export class Requerimiento {
  tpp_codigo?: number;
  tipoTarea?: number;
  seleccionado?: boolean;
  orden?: number;
  tar_codigo?: number;
  tar_denom: string;
  etar_codigo?: number;
  etar_denom: string;
  tar_fechapro?: Date;
  tar_fechasol?: Date;
  usu_codigo?: number;
  usu_denom: string;
  empr_codigo?: number;
  empr_denom: string;
  estadoPendientes: string;
  estadoFinal: string;
  emp_codigoasig?: number;
  prioriEmpresa?: number;
  emp_denomAsig: string;
  proy_denom: string;
  proy_nroint?: number;
  proy_numero: string;
  tar_fechafin?: Date;
  tar_horaspla?: number;
  tar_nroticket: string;
  tar_proyecto ?: number;
  tot_min ?: number;
  tot_hr?: number;
  hsimputadas?: number;
  hr_imputadas?: string;
}
