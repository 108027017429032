import { jsDocComment } from '@angular/compiler';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { promise } from 'protractor';
import { MenuItems } from './Entidades/MenuItems'
import { MenuesService } from './_services/menues.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  router: Router;
  intentos: number = 0;
  menuItems: MenuItems[] = [];
  idUsuario:number = Number(localStorage.getItem('idUsuario'));
  @ViewChild('appDrawer') appDrawer!: ElementRef;

  constructor(public service: MenuesService, private _router: Router) {
    this.router = _router;
    //this.canActivate();
  }

  async ngOnInit() {
    await this.CargarMenuJson();
  }

  async ngAfterViewInit(): Promise<void> {
    this.service.appDrawer = this.appDrawer;
   
  }

  async ngAfterViewChecked(): Promise<void> {
    if (this.router.url !== '/' && this.router.url !== '/login') {
    
      if (this.menuItems && this.menuItems.length === 0) {
        this.idUsuario = Number(localStorage.getItem('idUsuario'));
        try {
          this.cargarMenu(this.idUsuario);
        } catch (error) {
          console.log(error);
        }
      }

    }
  }     

  //canActivate(): boolean {
  //  if (localStorage.getItem('token') == null) {
  //    this.router.navigate(['login']);
  //    return false;
  //  } else {
  //    if (this.menu == 1) {
  //      this.router.navigate(['home']);
  //    } else if (this.menu == 2) {
  //      this.router.navigate(['homeAdmin']);
  //    }
  //    return false;
  //  }
  //}
  async CargarMenuJson(): Promise<void> {
    var menuJson = localStorage.getItem('menu');
    if (menuJson && menuJson.length > 0) {
      this.menuItems = JSON.parse(menuJson);
    }
  }
  public cargarMenu(idUsuario:number) {
    try {
      /*this.idUsuario = Number(localStorage.getItem('idUsuario'));;*/
      //alert(this.idUsuario)
     /* setTimeout(() => {*/
       
          console.log(this.idUsuario);
          this.service.devolverMenuxUsuario(2, idUsuario)
            .subscribe({
              next: (response) => {
                this.menuItems = response as MenuItems[];

              },
              error: (error) => {
                console.log(error);
                this.intentos++;
                if (this.intentos < 3) {
                  this.delay(1000);
                  this.intentos++;
                  //this.cargarMenu();
                }
              }
            });
        
   /*   },2000)*/
     
    } catch (error) {
      console.log(error);
      this.delay(1000);
      this.intentos++;
      //this.cargarMenu();
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
