import { Component, Inject, OnInit, ViewChild, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TareasService } from '../../../_services/tareas-service';

@Component({
  selector: 'app-dialog-detalles-envios',
  templateUrl: './dialog-detalles-envios.component.html',
  styleUrls: ['./dialog-detalles-envios.component.css']
})
export class DialogDetallesEnviosComponent implements OnInit {

  tareas: any[] = [];
  dataSourceTareas: any;
  columnasTareas: string[] = ['tar_codigo', 'tar_denom'];
  constructor(public dialogRef: MatDialogRef<DialogDetallesEnviosComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private tareasService: TareasService,
    private fb: FormBuilder) {

    if (data)
    {
      console.log(data)
      this.tareasService.cargarTareasPorEnvio(data.envio.lle_codigo).subscribe(
        response => {
          this.tareas = response;
          this.dataSourceTareas = new MatTableDataSource<any>(this.tareas);
        }, error => {
        }
      );
    }
    
  }

  ngOnInit(): void {
  }

}
