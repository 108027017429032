import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItems } from '../../Entidades/MenuItems';
import { MenuesService } from '../../_services/menues.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})

export class MenuItemComponent implements OnInit {
  expanded = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item!: MenuItems;
  @Input() depth: number;

  constructor(public service: MenuesService, public router: Router) {
    this.depth = 0;
  }

  ngOnInit(): void {
    this.service.currentUrl.subscribe((url: string) => {
      if (this.item.proc_formulario && url) {
        this.expanded = url.indexOf(`/${this.item.proc_formulario}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }
  
  onItemSelected(item: MenuItems) {
    console.log('menu item component', item);
    if (!item.men_codigo || !item.menuItemsHijos || item.menuItemsHijos.length === 0) {
      console.log(`${[item.proc_formulario]}`)
      this.router.navigate([item.proc_formulario]);
      this.service.cerrarMenu();
    }
    if (item.men_codigo && item.menuItemsHijos && item.menuItemsHijos.length) {
      this.expanded = !this.expanded;
    }
  }

    

}
