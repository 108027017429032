import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { LoginService } from '../../Servicios/login.servicio';
import { AuthService } from '../../_services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  usuario: string = "";
  pass: string = "";
  hide: boolean = true;
  beta: boolean = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private authService: AuthService,
    private appcomponent: AppComponent,
    private route: ActivatedRoute
  ) {}

  enviar() {
    /*this.loginService.post(this.usuario, this.pass, this.beta);*/
    const body = {
      'usu_webuser': this.usuario,
      'usu_webpass': this.pass
    }
    this.authService.login(body, this.beta).subscribe({
      next: async (response) => {
        this.authService.setSession(response);
        if (!(localStorage.getItem("token") == null)) {
          if (this.beta) {
            this.router.navigate(['/tablas']);
          }
          else {
            //this.router.navigate(['/tablas2']);      
            var id_usuario = Number(localStorage.getItem('idUsuario'));
            await this.appcomponent.cargarMenu(id_usuario);

            this.router.navigate(['/home']);
          }
        }
      },
      error: (error) => {

      }
    });
  }

  ingresarBeta(event: any) {
    this.beta = event.checked
  }

  ngOnInit(): void {    
     localStorage.setItem('filtroTareas','{}');   
    localStorage.setItem('filtro', '{}');

    this.route.queryParams.subscribe(params => {
      const glParam = params['_gl'];  
      if (glParam) {    
        this.router.navigate(['']);
      }
    });

  }

}
