import { Component, Inject, OnInit, ViewChild, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Empresa } from '../../Entidades/Empresa';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { TareasService } from '../../_services/tareas-service';
import { DatePipe } from '@angular/common';
import { Requerimiento } from '../../Entidades/Requerimiento';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RequerimientoExcel } from '../../Entidades/RequerimientoExcel';
import { DialogDetallesEnviosComponent } from '../tablas/dialog-detalles-envios/dialog-detalles-envios.component';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-tablas',
  templateUrl: './tablas.component.html',
  styleUrls: ['./tablas.component.css'],
})

export class TablasComponent implements OnInit {
  formConsulta: FormGroup;
  @ViewChild('tablePend', { read: MatSort, static: false }) sort1: MatSort;
  @ViewChild('tableTer', { read: MatSort, static: false }) sort2: MatSort;
  @ViewChild('tableEnvios', { read: MatSort, static: false }) sort3: MatSort;
  @ViewChild('tablePriori', { read: MatSort, static: false }) sort4: MatSort;

  listaExcelPendientes: RequerimientoExcel[];
  tareasPendientes: any[] = [];
  tareasPendientesPriori: any[] = [];
  tareasTerminadas: any[] = [];
  tareasTotales: any[] = [];
  dataSourcePendientes: any;
  dataSourcePendientesPriori: any;
  dataSourceTerminadas: any;
  columnasPendientes: string[] = ['seleccion', 'prioridad', 'tar_codigo', 'tar_denom', 'etar_denom', 'tar_fechaprod', 'tar_fechasol', 'estadoPendientes','estadoFinal'];
  columnasTerminadas: string[] = ['tar_codigo', 'tar_denom', 'etar_denom', 'tar_fechaprod', 'tar_fechasol'];
  columnasPendientesPriori: string[] = ['prioridad', 'usu_denom', 'tar_codigo', 'tar_denom', 'etar_denom', 'tpp_feccar', 'tpp_hora',];
  tablaPendientes: MatTableDataSource<any>;
  tablaTerminadas: MatTableDataSource<any>;
  empresaseleccionado: Empresa;
  filasListas: boolean;
  columnasListas: boolean;
  tareasOrdenadas: Requerimiento[] = [];
  btnPreConfirmar: boolean = false;
  usu_codigo: number = parseInt(localStorage.getItem("idUsuario"));
  usu_denom: string = localStorage.getItem("usu_denom");
  existeOrden: boolean = false;
  dataSourceEnvios: any;
  tablaEnvios: MatTableDataSource<any>;
  columnasEnvios: string[] = ['fecha', 'hora', 'emp_denom', 'estado', 'cantTareas','detalle'];
  listaEnvios: any[] = [];
  habilitarConfirmar: boolean;


  columnasNombres: { [key: string]: string } = {
    'orden': 'Prioridad',
    'tar_codigo': 'Requerimiento.',
    'tar_denom': 'Denominación.',
    'etar_denom': 'Estado Tarea',
    'tar_fechaprod': 'Fecha Prod.',
    'tar_fechasol': 'Fecha Sol.',
    'estadoPendientes': 'N° Priorización',
    'estadoFinal': 'N° Planificación',
    'proy_denom': 'Proyecto.',
    'proy_nroint': 'Proyecto Nro.Int',
    'proy_numero': 'Proyecto N°',
    'tar_fechafin': 'Fecha Fin.',
    'tar_horaspla': 'Horas Pla.',
    'tar_nroticket': 'Ticket N°.',
    'tar_proyecto': 'Proyecto N°.',
    'usu_denom': 'Usuario',
    'tpp_feccar': 'Fecha Priorización',
    'tpp_hora': 'Hora Priorización'
  };

  //dinamica tabla

  listaColumnas: any[] = [];
  displayedColumns: string[] = [];

  listaColumnasTerminadas: any [] =[];
  displayedColumnsTerminadas: string[] = [];

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private formBuilder: FormBuilder,
    private tareasService: TareasService,
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
  }

  empresasListado: Empresa[];
  ngOnInit(): void {
    let usu_cod = localStorage.getItem("idUsuario");
    this.http.get<Empresa[]>(this.baseUrl + 'api/empresas/' + usu_cod).subscribe(result => {
      this.empresasListado = result;
      if (this.empresasListado.length == 1) {
        this.empresaseleccionado = this.empresasListado[0];
        this.formConsulta.controls.empresa.setValue(this.empresaseleccionado);
        this.consultar();
      }
    }, error => console.error(error));
    this.formConsulta = this.formBuilder.group({
      empresa: [null, [Validators.required]]
    })
  }



  cargarTareas(empr_codigo: number) {
    let contador = 0;
    this.tareasOrdenadas = [];
    this.tareasPendientes = [];
    this.tareasTerminadas = [];
    this.tareasOrdenadas = [];
    this.tareasService.obtenerTareas(empr_codigo).subscribe((response) => {
      if (response) {
        this.tareasTotales = response.requerimientos;
        this.listaColumnas = response.columnasPendientes
        this.listaColumnasTerminadas = response.columnasTerminadas
        this.displayedColumns = ['seleccion', ...this.listaColumnas];
        this.displayedColumnsTerminadas = this.listaColumnasTerminadas;
        this.listaEnvios = response.envios;
        //devuelve la cantidad de columnas, se utiliza en css para dividir por el 100% del total de la tabla.
        document.documentElement.style.setProperty('--numero-columnas', this.listaColumnas.length.toString());
        document.documentElement.style.setProperty('--numero-columnasTer', this.listaColumnasTerminadas.length.toString());
        //
        for (let i = 0; i < this.tareasTotales.length; i++) {
          if (this.tareasTotales[i].tar_fechafin != null) {
            this.tareasTotales[i].tar_fechafin = this.formatearFecha(this.tareasTotales[i].tar_fechafin);
          }

          if (this.tareasTotales[i].tar_fechapro != null) {
            this.tareasTotales[i].tar_fechapro = this.formatearFecha(this.tareasTotales[i].tar_fechapro);
          }

          if (this.tareasTotales[i].tar_fechasol != null) {
            this.tareasTotales[i].tar_fechasol = this.formatearFecha(this.tareasTotales[i].tar_fechasol);
          }
          if (this.tareasTotales[i].tipoTarea == 1) {
            //verifico que si ya posee prioridad y la reubico de acuerdo a su orden existente en prioridadesempresadet.
            if (this.tareasTotales[i].prioriEmpresa != null) //&& this.tareasTotales[i].etar_codigo < 3
            {
              this.tareasTotales[i].prioriEmpresa = (contador + 1);
              this.tareasTotales[i].seleccionado = true;
              this.tareasTotales[i].orden = this.tareasTotales[i].prioriEmpresa
              this.tareasOrdenadas.push(this.tareasTotales[i]);
              contador = (contador + 1);
            }
            this.tareasPendientes.push(this.tareasTotales[i])
          }
          if (this.tareasTotales[i].tipoTarea == 2) {
            this.tareasTerminadas.push(this.tareasTotales[i])
          }
        }
        this.dataSourcePendientes = new MatTableDataSource<any>(this.tareasPendientes);
        this.dataSourcePendientes.sort = this.sort1;
        this.dataSourceTerminadas = new MatTableDataSource<any>(this.tareasTerminadas);
        this.dataSourceTerminadas.sort = this.sort2;
        this.dataSourceEnvios = new MatTableDataSource<any>(this.listaEnvios)
        this.dataSourceEnvios.sort = this.sort3;
      }
    });
    this.repopularPendientesPriorizadas();
  }

  formatearFecha(fecha: Date): string {
    return this.datePipe.transform(fecha, 'dd/MM/yyyy') || '';
  }

  consultar() {
    this.empresaseleccionado = this.formConsulta.controls.empresa.value;
    this.cargarTareas(this.empresaseleccionado.empr_codigo)
  }


  ordenar(event: any, tarea: any) {
    if (event.checked) {
      tarea.seleccionado = true;
      this.tareasOrdenadas.push(tarea)
      for (let i = 0; i < this.tareasOrdenadas.length; i++) {
        if (this.tareasOrdenadas[i].seleccionado) {
          this.tareasOrdenadas[i].orden = i + 1;
        }
        else {
          this.tareasOrdenadas[i].orden = null;
        }
      }
    }
    else {
      tarea.seleccionado = false;
      this.tareasOrdenadas = this.tareasOrdenadas.filter(item => item.tar_codigo !== tarea.tar_codigo);
      for (let i = 0; i < this.tareasOrdenadas.length; i++) {
        if (this.tareasOrdenadas[i].orden > tarea.orden)
          this.tareasOrdenadas[i].orden = this.tareasOrdenadas[i].orden - 1;
      }
      tarea.orden = null;
    }
  }

  preConfirmar() {
    this.btnPreConfirmar = true;
    this.dataSourcePendientes = new MatTableDataSource<any>(this.tareasOrdenadas);
  }

  cancelar() {
    this.btnPreConfirmar = false;
    //rearmo la lista inicial para cuando se clickea cancelar, se muestren como se seleccionaron anteriormente
    for (let i = 0; i < this.tareasPendientes.length; i++) {
      for (let p = 0; p < this.tareasOrdenadas.length; p++) {
        if (this.tareasPendientes[i].tar_codigo == this.tareasOrdenadas[p].tar_codigo) {
          this.tareasPendientes[i].seleccionado = this.tareasOrdenadas[p].seleccionado;
          this.tareasPendientes[i].orden = this.tareasOrdenadas[p].orden;
        }
      }
    }
    this.dataSourcePendientes = new MatTableDataSource<any>(this.tareasPendientes);
  }

  confirmar() {
    for (var i = 0; i < this.tareasOrdenadas.length; i++) {
      this.tareasOrdenadas[i].usu_codigo = this.usu_codigo;
      this.tareasOrdenadas[i].usu_denom = this.usu_denom;
      this.tareasOrdenadas[i].empr_codigo = this.empresaseleccionado.empr_codigo;
      this.tareasOrdenadas[i].empr_denom = this.empresaseleccionado.empr_razonSocial;
      if (this.tareasOrdenadas[i].tar_fechafin != null) {
        this.tareasOrdenadas[i].tar_fechafin = null;
      }

      if (this.tareasOrdenadas[i].tar_fechapro != null) {
        this.tareasOrdenadas[i].tar_fechapro = null;
      }

      if (this.tareasOrdenadas[i].tar_fechasol != null) {
        this.tareasOrdenadas[i].tar_fechasol = null;
      }
    }
    this.tareasService.guardarPriorizadas(this.tareasOrdenadas).subscribe(
      response => {
        this.repopularPendientesPriorizadas();
        this._snackBar.open('La priorización fue cargada correctamente', 'Ok', { duration: 5000, });
        this.btnPreConfirmar = false;
        this.tareasPendientes = [];
        this.enviarMailNotificacion(this.tareasOrdenadas);
        this.consultar();
      }, error => {
        this._snackBar.open('No se pudo realizar la priorización', 'Ok', { duration: 5000, });
      }
    );
  }

  repopularPendientesPriorizadas() {
    this.tareasService.cargarPendientesPriorizadas(this.empresaseleccionado.empr_codigo).subscribe(
      response => {
        this.tareasPendientesPriori = response;
        this.dataSourcePendientesPriori = new MatTableDataSource<any>(this.tareasPendientesPriori);
        this.dataSourcePendientesPriori.sort = this.sort4;
      }, error => {
        console.log('ERROR')
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3500,
    });
  }

  getFilaColor(pp: any): string {
    if (pp.estadoPendientes != null && pp.estadoFinal == null) {
      return 'color-fila-valor1';
    }

    if (pp.estadoFinal != null && pp.etar_denom != 'En Proceso' ) {
      return 'color-fila-valor2';
    }

    if (pp.etar_denom === 'En Proceso') {
      return 'color-fila-valor3';
    }
  }

  enviarMailNotificacion(tareas: Requerimiento[]) {
    this.tareasService.enviarMail(this.tareasOrdenadas).subscribe(
      response => {
      }, error => {
        console.log('ERROR')
      }
    );

  }

  getNombreCabecera(propiedad: string): string {
    return this.columnasNombres[propiedad] || propiedad;
  }

  armarObjetoExcel(tipoExcel: number, columnas: string[]) {
    let lista: any[] = [];
    let listaArecorrer: any[] = [];

    if (tipoExcel === 1) {
      listaArecorrer = this.tareasPendientes;
    }
    if (tipoExcel === 2) {
      for (var i = 0; i < this.tareasPendientesPriori.length; i++) {
        this.tareasPendientesPriori[i].tpp_feccar = this.formatearFecha(this.tareasPendientesPriori[i].tpp_feccar);
      }
      listaArecorrer = this.tareasPendientesPriori;
    }
    if (tipoExcel === 3) {
      listaArecorrer = this.tareasTerminadas;
    }

    listaArecorrer.forEach(requerimiento => {
      let requerimientoExcel: any = {};

      columnas.forEach(columna => {
        requerimientoExcel[this.getNombreCabecera(columna)] = requerimiento[columna];
      });

      lista.push(requerimientoExcel);
    });

    this.exportarExcel(lista, tipoExcel);
  }

  exportarExcel(lista: any[], tipoExcel: number): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lista);


    // Aplicar estilos a las celdas si es necesario
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');

    let fileName: string;
    switch (tipoExcel) {
      case 1:
        fileName = 'TareasPendientes.xlsx';
        break;
      case 2:
        fileName = 'TareasPriorizadas.xlsx';
        break;
      case 3:
        fileName = 'TareasTerminadas.xlsx';
        break;
    }

    XLSX.writeFile(wb, fileName);
  }

  getColumnStyle(columna: string): { [key: string]: string } {
    if (columna === 'tar_denom') {
      return {
        'width': '30%',
        'text-align': 'left'};
    }
    else if (columna === 'orden') {
      return { 'width': '5%' }; 
    }
    else if (columna === 'tar_codigo') {
      return { 'width': '10%' }; 
    }
    else if (columna === 'etar_denom') {
      return { 'width': '7%' }; 
    }
    else {
      return { 'width': '10%' }; 
    }
  }

  getHeaderStyle(columna: string): { [key: string]: string } {
    if (columna === 'tar_denom') {
      return {
        'width': '30%',
        'text-align':'left'
      };
    }
    else if (columna === 'orden') {
      return { 'width': '5%' };
    }
    else if (columna === 'tar_codigo') {
      return { 'width': '10%' };
    }
    else if (columna === 'etar_denom') {
      return { 'width': '7%' };
    }
    else if (columna === 'tar_fechaprod') {
      return { 'width': '10%' };
    }
    else {
      return { 'width': '10%' };
    }
  }


  getColumnStyleTer(columna: string): { [key: string]: string } {
    if (columna === 'tar_denom') {
      return {
        'width': '30%',
        'text-align': 'left'
      };
    }
  }

  abrirDialogDetalle(envio: any) {
    const dialogRef = this.dialog.open(DialogDetallesEnviosComponent, {
      data: {
       envio: envio
      },
    });
  }


  downloadPdf() {
    const pdfFilePath = 'assets/Manual/Instructivo.pdf'; 
    const link = document.createElement('a');
    link.href = pdfFilePath;
    link.download = 'Instructivo.pdf'; 

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  //armarObjetoExcel(tipoExcel: number) {
  //  let lista: RequerimientoExcel[] = [];
  //  if (tipoExcel == 1) {
  //    for (var i = 0; i < this.tareasPendientes.length; i++)
  //    {
  //      let requerimientoExcel: RequerimientoExcel = new RequerimientoExcel();
  //      requerimientoExcel.prioridad = this.tareasPendientes[i].orden;
  //      requerimientoExcel.requerimiento = this.tareasPendientes[i].tar_codigo;
  //      requerimientoExcel.denominacion = this.tareasPendientes[i].tar_denom;
  //      requerimientoExcel.estado = this.tareasPendientes[i].etar_denom;
  //      requerimientoExcel.fechaProd = this.tareasPendientes[i].tar_fechapro;
  //      requerimientoExcel.fechaSol = this.tareasPendientes[i].tar_fechasol;
  //      requerimientoExcel.orden = this.tareasPendientes[i].estadoPendientes;
  //      requerimientoExcel.priorizado = this.tareasPendientes[i].estadoFinal;
  //      lista.push(requerimientoExcel);
  //    }
  //    this.exportarExcel(lista, tipoExcel);
  //  }
  //  if (tipoExcel == 2) {
  //    for (var i = 0; i < this.tareasPendientesPriori.length; i++)
  //    {
  //      let requerimientoExcel: RequerimientoExcel = new RequerimientoExcel();
  //      requerimientoExcel.prioridad = this.tareasPendientesPriori[i].orden;
  //      requerimientoExcel.usuario = this.tareasPendientesPriori[i].usu_denom;
  //      requerimientoExcel.requerimiento = this.tareasPendientesPriori[i].tar_codigo;
  //      requerimientoExcel.denominacion = this.tareasPendientesPriori[i].tar_denom;
  //      requerimientoExcel.estado = this.tareasPendientesPriori[i].etar_denom;
  //      requerimientoExcel.fechaProd = this.tareasPendientesPriori[i].tpp_feccar;
  //      requerimientoExcel.hora = this.tareasPendientesPriori[i].tpp_hora;
  //      lista.push(requerimientoExcel);
  //    }
  //    this.exportarExcel(lista, tipoExcel);
  //  }
  //  if (tipoExcel == 3) {
  //    for (var i = 0; i < this.tareasTerminadas.length; i++)
  //    {
  //      let requerimientoExcel: RequerimientoExcel = new RequerimientoExcel();
  //      requerimientoExcel.requerimiento = this.tareasTerminadas[i].tar_codigo;
  //      requerimientoExcel.denominacion = this.tareasTerminadas[i].tar_denom;
  //      requerimientoExcel.estado = this.tareasTerminadas[i].etar_denom;
  //      requerimientoExcel.fechaProd = this.tareasTerminadas[i].tar_fechapro;
  //      requerimientoExcel.fechaSol = this.tareasTerminadas[i].tar_fechasol;
  //      lista.push(requerimientoExcel);
  //    }
  //    this.exportarExcel(lista, tipoExcel);
  //  }
  //}


  //exportarExcel(lista: RequerimientoExcel[], tipoExcel: number): void {
  //  if (tipoExcel == 1) {
  //    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lista);
  //    ws['A1'].v = 'Prioridad';
  //    ws['B1'].v = 'Nro. Requerimiento';
  //    ws['C1'].v = 'Denominación';
  //    ws['D1'].v = 'Estado';
  //    ws['E1'].v = 'Fecha Prod';
  //    ws['F1'].v = 'Fecha Sol';
  //    ws['G1'].v = 'Orden';
  //    ws['H1'].v = 'Priorizado'
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');
  //    let fileName = 'TareasPendientes.xlsx';
  //    XLSX.writeFile(wb, fileName);
  //  }
  //  if (tipoExcel == 2) {
  //    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lista);
  //    ws['A1'].v = 'Orden';
  //    ws['B1'].v = 'Priorizado por..';
  //    ws['C1'].v = 'Nro. Requerimiento';
  //    ws['D1'].v = 'Denominación';
  //    ws['E1'].v = 'Estado';
  //    ws['F1'].v = 'Fecha Priorización';
  //    ws['G1'].v = 'Hora';
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');
  //    let fileName = 'TareasPriorizadas.xlsx';
  //    XLSX.writeFile(wb, fileName);
  //  }
  //  if (tipoExcel == 3) {
  //    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lista);
  //    ws['A1'].v = 'Nro. Requerimiento';
  //    ws['B1'].v = 'Denominación';
  //    ws['C1'].v = 'Estado';
  //    ws['D1'].v = 'Fecha Prod';
  //    ws['E1'].v = 'Fecha Sol';
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');
  //    let fileName = 'TareasTerminadas.xlsx';
  //    XLSX.writeFile(wb, fileName);
  //  }
  ////}



}
