import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HorasService } from '../../_services/horas.service';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Horas } from '../../Entidades/Horas';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { strict } from 'assert';



@Component({
  selector: 'app-dlgtareadelmesporempresa',
  templateUrl: './dlgtareadelmesporempresa.component.html',
  styleUrls: ['./dlgtareadelmesporempresa.component.css']
})

export class DlgTareaDelMesPorEmpresaComponent implements OnInit {

  empr_codigo: number = 0;
  fecha: Date;
  fechaStr: string = "";
  fechaFormateada: string;
  empr_denom: string = "";

  totalSinAsignar?: number = 0;
  totalImplemPtes?: number = 0;
  totalAsignadas?: number = 0;
  totalFinalizadas?: number = 0;
  totalHsProc?: number = 0;
  totalHsSoporte?: number = 0;
  totalProySinAsignar?: number = 0;
  totalProyAsignadas?: number = 0;
  totalProyERE?: number = 0;
  totalProyFinalizadas?: number = 0;
  totalProyProceso?: number = 0;
  totalHsEstimadas?: number = 0;

  totalSeleccionadoSinAsignar: number = 0;
  totalSeleccionadoImplemPtes: number = 0;
  totalSeleccionadoAsignadas: number = 0;
  totalSeleccionadoFinalizadas: number = 0;
  totalSeleccionadoHsProc: number = 0;
  totalSeleccionadoHsSoporte: number = 0;
  totalSeleccionadoProySinAsignar: number = 0;
  totalSeleccionadoProyAsignadas: number = 0;
  totalSeleccionadoProyERE: number = 0;
  totalSeleccionadoProyFinalizadas: number = 0;
  totalSeleccionadoProyProceso: number = 0;
  totalSeleccionadoHsEstimadas: number = 0;

  promedioSinAsignar: number = 0;
  promedioImplemPtes: number = 0;
  promedioAsignadas: number = 0;
  promedioFinalizadas: number = 0;
  promedioHsProc: number = 0;
  promedioHsSoporte: number = 0;
  promedioProySinAsignar: number = 0;
  promedioProyAsignadas: number = 0;
  promedioProyERE: number = 0;
  promedioProyFinalizadas: number = 0;
  promedioProyProceso: number = 0;
  promedioHsEstimadas: number = 0;

  listaExcel: any[] = [];

  listaHorasEmpresas: Horas[] = [];

  listaSeleccion: Horas[] = [];

  columnas: string[] = ['tarea', 'denom', 'tarreplicade', 'fechafin', 'SinAsignar', 'ImplemPtes',
    'Asignadas', 'Finalizadas', 'HsProc', 'HsSoporte', 'ProySinAsignar', 'ProyAsignadas', 'ProyERE',
    'ProyFinalizadas', 'ProyProceso', 'tar_horaspla', 'proy_nroint', 'proy_numero', 'proy_denom', 'nro_ticket', 'empasignado','empdenom']
  dataSourceHorasEmpresas!: MatTableDataSource<Horas>;

  isLoadingResults = true;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(private horasService: HorasService, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar) {

  }


  vaciarTotalesSeleccionados() {

    this.totalSeleccionadoSinAsignar = 0;
    this.totalSeleccionadoImplemPtes = 0;
    this.totalSeleccionadoAsignadas = 0;
    this.totalSeleccionadoFinalizadas = 0;
    this.totalSeleccionadoHsProc = 0;
    this.totalSeleccionadoHsSoporte = 0;
    this.totalSeleccionadoProySinAsignar = 0;
    this.totalSeleccionadoProyAsignadas = 0;
    this.totalSeleccionadoProyERE = 0;
    this.totalSeleccionadoProyFinalizadas = 0;
    this.totalSeleccionadoProyProceso = 0;
    this.totalSeleccionadoHsEstimadas = 0;

    this.promedioSinAsignar = 0;
    this.promedioImplemPtes = 0;
    this.promedioAsignadas = 0;
    this.promedioFinalizadas = 0;
    this.promedioHsProc = 0;
    this.promedioHsSoporte = 0;
    this.promedioProySinAsignar = 0;
    this.promedioProyAsignadas = 0;
    this.promedioProyERE = 0;
    this.promedioProyFinalizadas = 0;
    this.promedioProyProceso = 0;
    this.promedioHsEstimadas = 0;

  }


  ngOnInit(): void {

    this.empr_codigo = +this.route.snapshot.paramMap.get('empr_codigo');

    const fechaStr: string = this.route.snapshot.paramMap.get('fecha');
    this.fecha = new Date(fechaStr);
    this.fechaFormateada = this.formatDate(this.fecha);
    
    this.DevolverHorasxEmpresa(this.empr_codigo, this.fecha);
    this.empr_denom;
    this.vaciarTotalesSeleccionados();

  }


  private formatDate(date: Date): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }


  DevolverHorasxEmpresa(empr_codigo: number, fecha: Date): void {
    this.horasService.DevolverHorasxEmpresa(this.empr_codigo, this.fecha )
      .subscribe({
        next: (response) => {
          this.listaHorasEmpresas = response.listaHoras as Horas[];
          this.dataSourceHorasEmpresas = new MatTableDataSource<Horas>(this.listaHorasEmpresas);
          this.dataSourceHorasEmpresas.sort = this.sort;
          this.dataSourceHorasEmpresas.paginator = this.paginator;
          this.isLoadingResults = false;

          this.totalSinAsignar = response.totalSinAsignar;
          this.totalImplemPtes = response.totalImplemPtes;
          this.totalAsignadas = response.totalAsignadas;
          this.totalFinalizadas = response.totalFinalizadas;
          this.totalHsProc = response.totalHsProc;
          this.totalHsSoporte = response.totalHsSoporte;
          this.totalProySinAsignar = response.totalProySinAsignar;
          this.totalProyAsignadas = response.totalProyAsignadas;
          this.totalProyERE = response.totalProyERE;
          this.totalProyFinalizadas = response.totalProyFinalizadas;
          this.totalProyProceso = response.totalProyProceso;
          this.totalHsEstimadas = response.totalHsEstimadas;
          this.empr_denom = response.empre_denom;        

        },
        error: (error) => {
          console.error(error)
        }
      });
  }

  Volver(): void {
    this.router.navigateByUrl('/horas');
  }



  armarObjetoExcel(x: any): void {

    var fechafinal;
    if (x.fechafin == '0001-01-01T00:00:00') {
      fechafinal = "";
    } else {
      const fechafinalDate = new Date(x.fechafin);
      const formatoInicio = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'       
      });
      fechafinal = formatoInicio.format(fechafinalDate);
    }

    let obj = {

      tarea: x.tarea,
      denom: x.denom,
      tarreplicade: x.tarreplicade,
      fechafin: fechafinal,
      sinAsignar: x.sinAsignar,
      implemPtes: x.implemPtes,
      asignadas: x.asignadas,
      finalizadas: x.finalizadas,
      hsProc: x.hsProc,
      hsSoporte: x.hsSoporte,
      proySinAsignar: x.proySinAsignar,
      proyAsignadas: x.proyAsignadas,
      proyERE: x.proyERE,
      proyFinalizadas: x.proyFinalizadas,
      proyProceso: x.proyProceso,
      tar_horaspla: x.tar_horaspla,
      proy_nroint: x.proy_nroint,
      proy_numero: x.proy_numero,
      proy_denom: x.proy_denom,
      nro_ticket: x.nro_ticket,
      empasignado: x.empasignado,
      empdenom: x.empdenom,

    };

    this.listaExcel.push(obj);
  }

  exportToExcel(): void {

    if (this.listaHorasEmpresas.length > 0) {
      this.listaHorasEmpresas.map((x) => {
        this.armarObjetoExcel(x);
      });
      const worksheetData = this.listaExcel.map(item => [
        item.tarea,
        item.denom,
        item.tarreplicade,
        item.fechafin,
        item.sinAsignar,
        item.implemPtes,
        item.asignadas,
        item.finalizadas,
        item.hsProc,
        item.hsSoporte,
        item.proySinAsignar,
        item.proyAsignadas,
        item.proyERE,
        item.proyFinalizadas,
        item.proyProceso,
        item.tar_horaspla,
        item.proy_nroint,
        item.proy_numero,
        item.proy_denom,
        item.nro_ticket,
        item.empasignado,
        item.empdenom
      ]);

      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
        ['Nro. Tarea', 'Denominación', 'Tarea Original','Fecha Finalización', 'Sin Asignación', 'Implem. Ptes', 'Asignadas', 'Finalizadas', 'En Proc.', 'Hs. Soporte', 'Proy SinAsig.', 'Proy. Asignadas', 'Proy. ERE', 'Proy. Finaliz.', 'Proy. Proceso', 'Hs. Estimadas', 'Proy. Nro Int.', 'Proy. Numero', 'Proy. Denom.', 'Nro. Ticket', 'Empleado Asignado', 'Denominación'],
        ...worksheetData
      ]);

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Hoja 1');
      let fileName = 'Tareas del Mes por Empresa.xlsx';
      XLSX.writeFile(wb, fileName);
    }

  }

  seleccionarFila(row: any) {
    const index = this.listaSeleccion.indexOf(row);
    if (index === -1) {
      this.listaSeleccion.push(row);
    } else {
      this.listaSeleccion.splice(index, 1);
    }
    this.CalcularTotalxFila();
  }

  filaSeleccionada(fila: any): boolean {
    return this.listaSeleccion.indexOf(fila) !== -1;
  }

  CalcularTotalxFila() {

    this.vaciarTotalesSeleccionados();

    if (this.listaSeleccion != undefined && this.listaSeleccion != null && this.listaSeleccion.length > 0) {
      for (var i = 0; i < this.listaSeleccion.length; i++) {

        // Calcular total segun seleccion
        this.totalSeleccionadoSinAsignar += Number(this.listaSeleccion[i].sinAsignar);
        this.totalSeleccionadoImplemPtes += Number(this.listaSeleccion[i].implemPtes);
        this.totalSeleccionadoAsignadas += Number(this.listaSeleccion[i].asignadas);
        this.totalSeleccionadoFinalizadas += Number(this.listaSeleccion[i].finalizadas);
        this.totalSeleccionadoHsProc += Number(this.listaSeleccion[i].hsProc);
        this.totalSeleccionadoHsSoporte += Number(this.listaSeleccion[i].hsSoporte);
        this.totalSeleccionadoProySinAsignar += Number(this.listaSeleccion[i].proySinAsignar);
        this.totalSeleccionadoProyAsignadas += Number(this.listaSeleccion[i].proyAsignadas);
        this.totalSeleccionadoProyERE += Number(this.listaSeleccion[i].proyERE);
        this.totalSeleccionadoProyFinalizadas += Number(this.listaSeleccion[i].proyFinalizadas);
        this.totalSeleccionadoProyProceso += Number(this.listaSeleccion[i].proyProceso);
        this.totalSeleccionadoHsEstimadas += Number(this.listaSeleccion[i].tar_horaspla);

        // Calcular promedio segun seleccion
        this.promedioSinAsignar = parseFloat((this.totalSeleccionadoSinAsignar / this.listaSeleccion.length).toFixed(1));
        this.promedioImplemPtes = parseFloat((this.totalSeleccionadoImplemPtes / this.listaSeleccion.length).toFixed(1));
        this.promedioAsignadas = parseFloat((this.totalSeleccionadoAsignadas / this.listaSeleccion.length).toFixed(1));
        this.promedioFinalizadas = parseFloat((this.totalSeleccionadoFinalizadas / this.listaSeleccion.length).toFixed(1));
        this.promedioHsProc = parseFloat((this.totalSeleccionadoHsProc / this.listaSeleccion.length).toFixed(1));
        this.promedioHsSoporte = parseFloat((this.totalSeleccionadoHsSoporte / this.listaSeleccion.length).toFixed(1));
        this.promedioProySinAsignar = parseFloat((this.totalSeleccionadoProySinAsignar / this.listaSeleccion.length).toFixed(1));
        this.promedioProyAsignadas = parseFloat((this.totalSeleccionadoProyAsignadas / this.listaSeleccion.length).toFixed(1));
        this.promedioProyERE = parseFloat((this.totalSeleccionadoProyERE / this.listaSeleccion.length).toFixed(1));
        this.promedioProyFinalizadas = parseFloat((this.totalSeleccionadoProyFinalizadas / this.listaSeleccion.length).toFixed(1));
        this.promedioProyProceso = parseFloat((this.totalSeleccionadoProyProceso / this.listaSeleccion.length).toFixed(1));
        this.promedioHsEstimadas = parseFloat((this.totalSeleccionadoHsEstimadas / this.listaSeleccion.length).toFixed(1));

      }
    }
  }

  mostrarTarea(row: any) {
    this.router.navigate(['/mostrarTarea', row.tarea, 2]);
  }



}
