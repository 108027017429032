import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Validator } from "../Entidades/Validator";

import { AuthService } from "../_services/auth.service";

@Injectable({ providedIn: 'root' })
export class LoginService {
  resourceUrl: string;

  constructor(
    private authService: AuthService,
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient
  ) {
  }

  cambiarPassword( nuevaContra: string, viejaContra: string) {
    const body = {
      'viejaContraseña': viejaContra,
      'nuevaContraseña': nuevaContra
    }
    return this.http.post<Validator>(this.baseUrl + "api/login/CambiarPassword", body);
  }


  recuperarPassword(user: string) {
    const body = {
      'usu_webuser': user
    }

    return this.http.post<Validator>(this.baseUrl + "api/login/RecuperarPassword", body);
  }

  recuperarUsuario(user: string) {
    const body = {
      'usu_webuser': user
    }
    return this.http.post<Validator>(this.baseUrl + "api/login/RecuperarUser", body);
  }


  post(user: string, pass: string, ingresarBeta: boolean ) {
    const body = {
      'usu_webuser': user,
      'usu_webpass': pass
    }
    return this.authService.login(body, ingresarBeta);
  }



}
