import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { log } from "util";
import { LoginService } from "../../../Servicios/login.servicio";





@Component({
  selector: 'component-cambioPass',
  templateUrl: './cambio-pass.component.html',
  styleUrls: ['./cambio-pass.component.css'],
})
export class CambioPass implements OnInit {
  oldPass: string = '';
  newPass: string = '';
  newPass2: string = '';

  hideOldPass: boolean = true;
  hideNewPass: boolean = true;
  hideNewPass2: boolean = true;

  samePass: boolean = false;

  constructor(
    private loginService: LoginService,
    @Inject('BASE_URL') private baseUrl: string, private _snackBar: MatSnackBar) { }


  ngOnInit(): void { }

  OnInputChange(): void {
    if (this.newPass.length > 0 && this.newPass2.length > 0) {
      if (this.newPass !== this.newPass2) {
        this.samePass = false;
        console.log('Las contraseñas no coinciden');
      } else {
        this.samePass = true;
      }
    }
  }


  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }



  cambiarPassword(): void {
      if (this.oldPass == "") {
        this.openSnackBar("Debe Ingresar su contraseña actual.", 'Ok');
        return;
      }

      if (this.newPass == "") {
        this.openSnackBar("Debe Ingresar su nueva contraseña a modificar.", 'Ok');
        return;
      }

    if (this.newPass2 == "") {
        this.openSnackBar("Debe repetir la nueva contraseña a modificar.", 'Ok');
        return;
    }

    if (this.newPass === this.newPass2 && this.oldPass.length > 0) {
      this.loginService.cambiarPassword(this.newPass, this.oldPass).subscribe(
        resp => {
          if (resp === null || resp.alertas.length === 0) {
            this.openSnackBar("No se pudo cambiar la contraseña", "Ok")
          } else {
            this.openSnackBar(resp.alertas[0], 'OK');
          }
        }
           
      )
    }
  }

}
