import { Component, Inject, OnInit, ViewChild, NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class MenuesService {
  public appDrawer: any;
  appUrl: string;
  apiUrl: string;
  public abierto: boolean;
  public currentUrl = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    this.abierto = false;
  }

  devolverMenuxUsuario(men_codigo: number, opew_codigo: number): Observable<any> {   
    let params: HttpParams = new HttpParams();
    params = params.append('menu', men_codigo.toString());
    params = params.append('usuario', opew_codigo.toString());

    return this.http.get(this.baseUrl + 'api/menues/DevolverMenuxUsuario', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
    
  }

  public cerrarMenu(): void {
    this.appDrawer.close();
    console.log('cerrarMenu', this.appDrawer);
    this.abierto = false;
  }

  public abrirMenu(): void {
    this.appDrawer.open();
    console.log('abrirMenu', this.appDrawer);
    this.abierto = true;
  }

  //errorHandler(error): Observable<never> {
  //  let errorMessage = '';
  //  if (error.error instanceof ErrorEvent) {
  //    // Error del lado del cliente
  //    errorMessage = error.error.message;
  //  } else {
  //    // Error del lado del servidor
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }
  //  console.error(errorMessage);
  //  return throwError(error);
  //}

  errorHandler(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        this.router.navigate(['/']);
      }
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }




}
