import { Component, Inject, OnInit, ViewChild, NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Requerimiento } from '../Entidades/Requerimiento';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class TareasService {
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,) {

  }

  obtenerTareas(empr_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.http
      .get(this.baseUrl + "api/empresas/requerimientos/" + empr_codigo, {
      })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  guardarPriorizadas(tareas: Requerimiento[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.baseUrl + 'api/empresas/requerimientos/insertarPendientesPriorizadas', tareas, httpOptions)
      .pipe(catchError(this.errorHandler.bind(this)));
  }

  enviarMail(tareas: Requerimiento[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.baseUrl + 'api/empresas/requerimientos/mailPriorizadas', tareas, httpOptions)
      .pipe(catchError(this.errorHandler.bind(this)));
  }

  cargarPendientesPriorizadas(empr_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.http
      .get(this.baseUrl + "api/empresas/pendientesPriorizadas/" + empr_codigo, {
      })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  cargarTareasPorEnvio(lle_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.http
      .get(this.baseUrl + "api/empresas/tareasPorEnvio/" + lle_codigo, {
      })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverListadoEmpresas(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/tareas/DevolverListadoEmpresas')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }  

  public DevolverEstadosTareas(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/tareas/DevolverEstadosTareas')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }


  public DevolverListadoTareas(empr_codigo: number, get_codigo: number, textoBuscar: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('empr_codigo', empr_codigo.toString());
    params = params.append('get_codigo', get_codigo.toString());
    params = params.append('textoBuscar', textoBuscar);

    return this.http.get(this.baseUrl + 'api/tareas/DevolverListadoTareas', { params } )
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverTareaxCodigo(tarCodigo: number, valor: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('tarCodigo', tarCodigo.toString());    
    params = params.append('valor', valor.toString());

    return this.http.get(this.baseUrl + 'api/tareas/DevolverTareaxCodigo', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverImputacionHoras(tar_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('tar_codigo', tar_codigo.toString());
   
    return this.http.get(this.baseUrl + 'api/tareas/DevolverImputacionHoras', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverComentariosTareas(tar_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('tar_codigo', tar_codigo.toString());

    return this.http.get(this.baseUrl + 'api/tareas/DevolverComentariosTareas', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  //errorHandler(error): Observable<never> {
  //  let errorMessage = '';
  //  if (error.error instanceof ErrorEvent) {
  //    // Error del lado del cliente
  //    errorMessage = error.error.message;
  //  } else {
  //    // Error del lado del servidor
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }
  //  console.error(errorMessage);
  //  return throwError(error);
  //}

  errorHandler(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        this.router.navigate(['/']);
      }
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }



}
