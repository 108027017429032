import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuItems } from '../../Entidades/MenuItems';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  router: Router;
  menuItems: MenuItems[] = [];
  intentos: number = 0;
  ope_denom: string = '';

  constructor(private dialog: MatDialog,
    private _router: Router) {   
    this.router = _router;
  }

  ngOnInit(): void {
    this.intentos = 0;
   
  }

}
