import { Component, Inject, OnInit, ViewChild, NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Reclamos } from '../Entidades/Reclamos';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})


export class ReclamosService {
  headers: HttpHeaders | { [header: string]: string | string[]; };
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,) {
  }

  public DevolverListadoEmpresas(usu_codigo: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('usu_codigo', usu_codigo.toString());   

    return this.http.get(this.baseUrl + 'api/reclamo/DevolverListadoEmpresas', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverListadoMotivosReclamos(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/reclamo/DevolverListadoMotivosReclamos')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public InsertarReclamo(reclamo: Reclamos): Observable<any> {

    return this.http.post(this.baseUrl + 'api/reclamo/InsertarReclamo', reclamo, { headers: this.headers })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverListadoReclamos(empr_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('empr_codigo', empr_codigo.toString());
    return this.http.get(this.baseUrl + 'api/reclamo/DevolverListadoReclamos', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverReclamoxCodigo(rec_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('rec_codigo', rec_codigo.toString());

    return this.http.get(this.baseUrl + 'api/reclamo/DevolverReclamoxCodigo', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public ProcesoxUsuario(usu_codigo: string, proc_codigo: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('usu_codigo', usu_codigo.toString());
    params = params.append('proc_codigo', proc_codigo.toString());

    return this.http.get(this.baseUrl + 'api/reclamo/ProcesoxUsuario', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverUsuariosInternos(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/reclamo/DevolverUsuariosInternos')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverConsultaReclamos(rec_codigo: number, FechaDesde: Date, FechaHasta: Date, empr_codigo: number, mot_codigo: number, rec_valido: number, usu_codigo: number, usu_interno: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('rec_codigo', rec_codigo.toString());
    params = params.append('fecha_desde', FechaDesde.toISOString());
    params = params.append('fecha_hasta', FechaHasta.toISOString());
    params = params.append('empr_codigo', empr_codigo.toString());
    params = params.append('mot_codigo', mot_codigo.toString());
    params = params.append('rec_valido', rec_valido.toString());
    params = params.append('usu_codigo', usu_codigo.toString());
    params = params.append('usu_interno', usu_interno.toString());

    return this.http.get(this.baseUrl + 'api/reclamo/DevolverConsultaReclamos', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverUsuarios(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/reclamo/DevolverUsuarios')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  //errorHandler(error): Observable<never> {
  //  let errorMessage = '';
  //  if (error.error instanceof ErrorEvent) {
  //    // Error del lado del cliente
  //    errorMessage = error.error.message;
  //  } else {
  //    // Error del lado del servidor
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }
  //  console.error(errorMessage);
  //  return throwError(error);
  //}


  errorHandler(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        this.router.navigate(['/']);
      }
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }






}
