import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  constructor(
    private router: Router
  ){ }

  usu_denom: string;
  esSesionValida(): boolean{
    if(AuthService.esSesionValida()){
      this.usu_denom = localStorage.getItem("usu_denom");
      return true;
    }
    else {
      this.cerrarSesion();
      return false;
    }
  }

  cerrarSesion(){
    AuthService.logout();
  }
}
