import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-alerta-simple',
  templateUrl: './dialog-alerta-simple.component.html',
  styleUrls: ['./dialog-alerta-simple.component.css']
})
export class DialogAlertaSimpleComponent implements OnInit {
  @Input() backgroundColor: string = '#fff';
  @Input() message: string = '';
  @Input() imageUrl: string = '';

  constructor(public dialogRef: MatDialogRef<DialogAlertaSimpleComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { mensaje: any }) {
    this.message = dialogData.mensaje;
  }
  ngOnInit(): void {
  }

}
