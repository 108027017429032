import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';

const TOKEN_HEADER_KEY = 'Authorization'; //Nombre de la etiqueta en el header


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const idToken = localStorage.getItem("token");
    if (idToken && AuthService.esSesionValida()) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization",
        "Bearer " + idToken)
      });
      return next.handle(cloned);
    }
    else {
      AuthService.logout();

      return next.handle(req);
    }
  };
}
