import { AppComponent } from './app.component';
import { LoginComponent } from './Componentes/login/login.component';
import { TablasComponent } from './Componentes/tablas/tablas.component';
import { Tablas2Component } from './Componentes/tablas/tablas2.component';
import { TareaComponent } from './Componentes/tarea/tarea.component';
import { DlgMostrarTareaComponent } from './Componentes/tarea/dlgmostrartarea.component';
import { DialogDetallesEnviosComponent } from './Componentes/tablas/dialog-detalles-envios/dialog-detalles-envios.component';
import { TareasService } from './_services/tareas-service';
import { HomeComponent } from './Componentes/home/home.component';
import { TopNavComponent } from './Componentes/top-nav/top-nav.component';
import { MenuItemComponent } from './Componentes/menu-item/menu-item.component';
import { HorasComponent } from './Componentes/horas/horas.component';
import { DlgTareaDelMesPorEmpresaComponent } from './Componentes/horas/dlgtareadelmesporempresa.component';
import { DialogAlertaSimpleComponent } from './Componentes/alerta/dialog-alerta-simple.component';
import { CambioPass } from './Componentes/Configuracion/CambioPass/cambio-pass.component';
import { RecuperarContrasenia } from './Componentes/recuperar-contrasenia/recuperar-contrasenia.component';
import { RecuperarUsuario } from './Componentes/recuperar-usuario/recuperar-usuario.component';
import { ReclamosComponent } from './Componentes/reclamos/reclamos.component';
import { Consulta_ReclamosComponent } from './Componentes/Consulta_Reclamos/Consulta_Reclamos.component';


// Imports de Angular Materials
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NavMenuComponent } from './Componentes/nav-menu/nav-menu.component';
import { MatSortModule } from '@angular/material/sort';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthInterceptor } from './_helpers/auth.interceptor';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// Clases, entidades

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TablasComponent,
    NavMenuComponent,
    DialogDetallesEnviosComponent,
    Tablas2Component,
    TareaComponent,
    DlgMostrarTareaComponent,
    HomeComponent,
    TopNavComponent,
    MenuItemComponent,
    HorasComponent,
    DlgTareaDelMesPorEmpresaComponent,
    DialogAlertaSimpleComponent,
    CambioPass,
    RecuperarContrasenia,
    RecuperarUsuario,
    ReclamosComponent,
    Consulta_ReclamosComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatSliderModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatButtonToggleModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSortModule,
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    MatPaginatorModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'tablas', component: TablasComponent },
      { path: 'tablas2', component: Tablas2Component },
      { path: 'tarea', component: TareaComponent },
      { path: 'mostrarTarea/:tar_codigo/:valor', component: DlgMostrarTareaComponent },
      { path: 'home', component: HomeComponent },
      { path: 'horas', component: HorasComponent },
      { path: 'reclamos', component: ReclamosComponent },
      { path: 'Consulta_Reclamos', component: Consulta_ReclamosComponent },
      { path: 'MostrarHorasxEmpresa/:fecha/:empr_codigo', component: DlgTareaDelMesPorEmpresaComponent },
      { path: 'dialog-alerta-simple', component: DialogAlertaSimpleComponent },
      { path: 'actpass', component: CambioPass },
      { path: 'recuperar/pass', component: RecuperarContrasenia },
      { path: 'recuperar/usuario', component: RecuperarUsuario },
      { path: '**', redirectTo: '' },
      
    ],
      { relativeLinkResolution: 'legacy' }),
    BrowserAnimationsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }, TareasService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
