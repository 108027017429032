export class Reclamos {
  rec_codigo: number;
  rec_fecha: Date;
  rec_denom: string;
  empr_codigo: number;
  mot_codigo: number;
  rec_descrip: string;
  rec_valido: number;
  rec_respu: string;
  usu_codigo: number;
  usu_denom: string;
  rec_validoStr: string;
  usu_interno: number;
  usu_interno_denom: string;
}
