export class ImputacionHoras {

  hr_fecha?: Date;
  ti_codigo?: number;
  ti_denom: string;
  emp_denom?: string;
  hr_minutos?: number;
  horas?: number;
  propor_envio?: number;
  total?: number;
  hr_observ?: string;
  hr_feccar?: string;
  tot_min?: number;
  tot_hr?: number;
  
}
