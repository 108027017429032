import { Component, OnInit, ViewChild } from "@angular/core";
import { ReclamosService } from "../../_services/reclamos.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Empresa } from '../../Entidades/Empresa';
import { Motivo_Reclamo } from '../../Entidades/Motivo_Reclamo';
import { Reclamos } from "../../Entidades/Reclamos";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Usuario } from "../../Entidades/Usuario";

@Component({
  selector: 'app-reclamos',
  templateUrl: './reclamos.component.html',
  styleUrls: ['./reclamos.component.css'],
})

export class ReclamosComponent implements OnInit {

  usu_codigo: string = "";
  usu_denom: string = "";
  listaEmpresa: Empresa[] = [];
  listaMotivos: Motivo_Reclamo[] = [];
  selectedEmpresa: number = 0;
  selectedMotivo: number = 0;
  reclamo: Reclamos = new Reclamos();
  rec_codigo: number = 0;
  rec_descrip: string = "";
  rec_respu: string = "";
  isChecked = false;
  searchTerm: string = '';
  buscarTerm: string = '';

  rec_validoStr: string = '';

  habilitado: boolean = false;

  columnas: string[] = ['rec_codigo', 'rec_fecha', 'empr_denom', 'mot_denom', 'rec_descrip', 'rec_respu', 'usu_denom','rec_valido']

  listaReclamos: Reclamos[] = [];
  listaReclamosSeleccionados: Reclamos[] = [];
  dataSourceReclamos!: MatTableDataSource<Reclamos>;

  buscarTermino: string = '';
  listaUsuariosInternos: Usuario[] = [];
  selectedOperador: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private reclamoService: ReclamosService, private _snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
  }


  ngOnInit(): void {

    this.usu_codigo = localStorage.getItem("idUsuario");
    this.usu_denom = localStorage.getItem("usu_denom"); 
    this.DevolverListadoMotivosReclamos();
    this.DevolverListadoReclamos();
    this.DevolverListadoEmpresas();
    this.DevolverUsuariosInternos();

    this.reclamoService.ProcesoxUsuario(this.usu_codigo, 61115).subscribe(
      (resultado: boolean) => {
        this.habilitado = resultado;
      },
      (error) => {
        console.error('Error al verificar proceso por usuario', error);
      }
    );

  }

  onCheckboxChange(event: any) {
    this.isChecked = event.checked;

  }

  get filteredEmpresas() {
    if (!this.searchTerm.trim()) {
      return this.listaEmpresa;
    }
    return this.listaEmpresa.filter(em =>
      em.empr_denom.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get filteredMotivos() {
    if (!this.buscarTerm.trim()) {
      return this.listaMotivos;
    }
    return this.listaMotivos.filter(mot =>
      mot.mot_denom.toLowerCase().includes(this.buscarTerm.toLowerCase())
    );
  }

  get filteredOperadores() {
    if (!this.buscarTermino.trim()) {
      return this.listaUsuariosInternos;
    }
    return this.listaUsuariosInternos.filter(usu =>
      usu.usu_denom.toLowerCase().includes(this.buscarTermino.toLowerCase())
    );
  }

  onSelectionChange() {
    this.searchTerm = '';
    this.DevolverListadoReclamos();
  }

  onSelectionChange2() { 
    this.buscarTerm = '';   
  }

  onSelectionChange3() {
    this.buscarTermino = '';
  }


  DevolverListadoEmpresas() {
    this.reclamoService.DevolverListadoEmpresas(this.usu_codigo).subscribe(
      response => {
        this.listaEmpresa = response;
        if (this.listaEmpresa == null) {
          this.openSnackBar("El usuario no tiene una empresa asignada", 'Ok');
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  DevolverUsuariosInternos() {
    this.reclamoService.DevolverUsuariosInternos().subscribe(
      response => {
        this.listaUsuariosInternos = response;      
      },
      error => {
        console.error(error)
      }
    );
  }

  DevolverListadoMotivosReclamos() {
    this.reclamoService.DevolverListadoMotivosReclamos().subscribe(
      response => {
        this.listaMotivos = response;
        if (this.listaMotivos != null) {

          var nuevoItem: Motivo_Reclamo = new Motivo_Reclamo();
          nuevoItem.mot_codigo = 0;
          nuevoItem.mot_denom = "Sin Definir";
          this.listaEmpresa = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }



  validarCampos(): boolean {

    if (this.selectedEmpresa === 0) {
      this.openSnackBar("Debe seleccionar su Empresa", 'Ok');      
      return false;
    }

    if (this.selectedMotivo === 0) {
      this.openSnackBar("Debe seleccionar un Motivo", 'Ok');
      return false;
    }

    if (!this.rec_descrip.trim()) {
      this.openSnackBar("Debe ingresar el detalle del reclamo", 'Ok');
      return false;
    }

    return true;
  }


  private inicializarForm(): void {
    this.rec_codigo = 0;
    this.selectedEmpresa = 0;
    this.selectedMotivo = 0;
    this.isChecked = false;
    this.rec_descrip = "";
    this.rec_respu = "";
    this.selectedOperador = 0;
  }


  Insertar_Reclamo() {
    if (this.validarCampos()) {
      this.reclamo.rec_codigo = this.rec_codigo;
      this.reclamo.empr_codigo = this.selectedEmpresa;
      this.reclamo.mot_codigo = this.selectedMotivo;
      this.reclamo.rec_descrip = this.rec_descrip;
      this.reclamo.rec_respu = this.rec_respu;
      this.reclamo.usu_codigo = Number(this.usu_codigo);
      this.reclamo.rec_valido = this.isChecked ? 1 : 0;
      this.reclamo.usu_interno = this.selectedOperador;

  

      this.reclamoService.InsertarReclamo(this.reclamo).subscribe(
        response => {
          if (this.reclamo.rec_codigo == 0) {
            this.openSnackBar("El reclamo se registró correctamente", 'Ok');
          }
          else {
            this.openSnackBar("El reclamo se modificó correctamente", 'Ok');
          }
          
          this.DevolverListadoReclamos();
          this.inicializarForm();
        },
        error => {
          console.error(error);
          this.openSnackBar("Error al insertar el reclamo", 'Ok');
        }
      );
    }
  }



  DevolverListadoReclamos() {
    if (this.selectedEmpresa !== 0) {
      this.reclamoService.DevolverListadoReclamos(this.selectedEmpresa)
        .subscribe({
          next: (response) => {
            
            if (!response) {
              this.listaReclamos = [];
              this.openSnackBar('No se encontraron reclamos registrados.', 'OK');
            } else {
              this.listaReclamos = response as Reclamos[];
              if (this.listaReclamos.length === 0) {
                this.openSnackBar('No se encontraron reclamos registrados.', 'OK');
              }
            }
            
            this.dataSourceReclamos = new MatTableDataSource<Reclamos>(this.listaReclamos);
            this.dataSourceReclamos.sort = this.sort;
            this.dataSourceReclamos.paginator = this.paginator;
          },
          error: (error) => {
            console.error('Error al obtener la lista de reclamos:', error);
            this.openSnackBar('Ocurrió un error al obtener los datos.', 'OK');
           
            this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
          }
        });
    } else {
      
      this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
    }
  }


  DevolverReclamoxCodigo(row: any): void {
    this.reclamoService.DevolverReclamoxCodigo(row.rec_codigo)
      .subscribe({
        next: (response) => {
          this.reclamo = response as Reclamos;
          this.rec_codigo = this.reclamo.rec_codigo;
          this.selectedMotivo = this.reclamo.mot_codigo;
          this.selectedEmpresa = this.reclamo.empr_codigo;
          this.usu_denom = this.reclamo.usu_denom.toString();
          this.isChecked = this.reclamo.rec_valido === 1;
          this.rec_descrip = this.reclamo.rec_descrip;
          this.rec_respu = this.reclamo.rec_respu;
          this.selectedOperador = this.reclamo.usu_interno;
    
        },
        error: (error) => {
          console.error(error)
        }
      });
  }


  seleccionarFila(row: any) {
    const index = this.listaReclamosSeleccionados.indexOf(row);
    if (index === -1) {
      this.listaReclamosSeleccionados.push(row);
    } else {
      this.listaReclamosSeleccionados.splice(index, 1);
    }

  }

  filaSeleccionada(fila: any): boolean {
    return this.listaReclamosSeleccionados.indexOf(fila) !== -1;
  }


  Cancelar() {    
      this.inicializarForm();
      this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
  }


}
