import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Validator } from "../../Entidades/Validator";
import { LoginService } from "../../Servicios/login.servicio";



@Component({
  selector: 'app-recuperar-contrasenia',
  templateUrl: './recuperar-contrasenia.component.html',
  styleUrls: ['./recuperar-contrasenia.component.css'],
})
export class RecuperarContrasenia {
  mail:string = '';


  constructor(private loginService: LoginService, private _snackBar: MatSnackBar) { }


  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }

  recuperarPass(): void {
    this.loginService.recuperarPassword(this.mail).subscribe(
      resp => {
        if (resp === null || resp.alertas.length === 0) {
          alert("El usuario no existe")
        } else {
          this.openSnackBar(resp.alertas[0], 'OK');
        }
      }
    )
  }  
  
}
