import { Component, Inject, OnInit, ViewChild, NgModule, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class HorasService {
  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string,) {
  }

  public DevolverListadoEmpresas(): Observable<any> {
    return this.http.get(this.baseUrl + 'api/tareas/DevolverListadoEmpresas')
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverListadoHoras(empr_codigo: number, codigo: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('empr_codigo', empr_codigo.toString());
    params = params.append('codigo', codigo.toString());

    return this.http.get(this.baseUrl + 'api/horas/DevolverListadoHoras', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  public DevolverHorasxEmpresa(empr_codigo: number, fecha: Date): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (empr_codigo !== undefined) {
      params = params.append('empr_codigo', empr_codigo.toString());
    }

    if (fecha instanceof Date && !isNaN(fecha.getTime())) {
      params = params.append('fecha', fecha.toISOString());
    }

    return this.http.get(this.baseUrl + 'api/horas/DevolverHorasxEmpresa', { params })
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  //  errorHandler(error): Observable < never > {
  //    let errorMessage = '';
  //    if(error.error instanceof ErrorEvent) {
  //    // Error del lado del cliente
  //    errorMessage = error.error.message;
  //  } else {
  //    // Error del lado del servidor
  //    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //  }
  //  console.error(errorMessage);
  //  return throwError(error);
  //}

  errorHandler(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = error.error.message;
    } else {
      if (error.status === 401) {
        this.router.navigate(['/']);
      }
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }



  }
