import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Empresa } from '../../Entidades/Empresa';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { TareasService } from '../../_services/tareas-service';
import { DatePipe } from '@angular/common';
import { Requerimiento } from '../../Entidades/Requerimiento';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EstadoTarea } from '../../Entidades/EstadoTarea';
import { MatPaginator } from '@angular/material/paginator'; 
import { ImputacionHoras } from '../../Entidades/ImputacionHoras';
import { Tarea_Comentario } from '../../Entidades/Tarea_Comentario';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dlgmostrartarea',
  templateUrl: './dlgmostrartarea.component.html',
  styleUrls: ['./dlgmostrartarea.component.css'],
})

export class DlgMostrarTareaComponent implements OnInit {
  
  imp: ImputacionHoras = new ImputacionHoras();
  tar_codigo: number = 0;
  tar_denom: string = '';
  tar_horaspla: string = '';
  tot_min: number = 0;
  tot_hr: number = 0;
  hr_imputadas: string = "";
  Req: Requerimiento = new Requerimiento();

  valor: number = 0;

  listaImpHoras: ImputacionHoras[] = [];
  columnas: string[] = ['hr_fecha', 'ti_codigo', 'ti_denom', 'emp_denom', 'hr_minutos', 'horas', 'propor_envio', 'total', 'hr_observ', 'hr_feccar']
  dataSourceImpHorasTareas!: MatTableDataSource<ImputacionHoras>;

  listaComTareas: Tarea_Comentario[] = [];
  columnasComTareas: string[] = ['tcom_Feccar', 'tcom_comen', 'emp_denom']
  dataSourceComTareas!: MatTableDataSource<Tarea_Comentario>; 

  isLoadingResults = true;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort1!: MatSort;

  constructor(private tareaService: TareasService, private router: Router, private route: ActivatedRoute) {
  
  }


  ngOnInit(): void {
    this.tar_codigo = Number(this.route.snapshot.paramMap.get('tar_codigo'));
    this.valor = Number(this.route.snapshot.paramMap.get('valor'));
    this.DevolverImputacionHoras();
    this.DevolverComentariosTareas();
    this.DevolverTareaxCodigo(this.tar_codigo);
  }

  DevolverTareaxCodigo(tarCodigo: number): void {
    this.tareaService.DevolverTareaxCodigo(tarCodigo, this.valor)
      .subscribe({
        next: (response) => {
          this.Req = response as Requerimiento;
          this.tar_codigo = this.Req.tar_codigo;
          this.tar_denom = this.Req.tar_denom;
          this.tar_horaspla = this.Req.tar_horaspla.toString();
          this.hr_imputadas = this.Req.hr_imputadas.toString();
          this.isLoadingResults = false;
        },
        error: (error) => {
          console.error(error)
        }
      });
  }

  DevolverImputacionHoras() {
    this.tareaService.DevolverImputacionHoras(this.tar_codigo)
      .subscribe({
        next: (response) => {
          this.listaImpHoras = response.listaImputacion as ImputacionHoras[];
          this.dataSourceImpHorasTareas = new MatTableDataSource<ImputacionHoras>(this.listaImpHoras);
          this.dataSourceImpHorasTareas.sort = this.sort;
          this.dataSourceImpHorasTareas.paginator = this.paginator;
          this.isLoadingResults = false;
          this.tot_hr = response.tot_hr;
          this.tot_min = response.tot_min;
        },
        error: (error) => {
          console.error(error)
        }
      });
  }

  DevolverComentariosTareas() {
    this.tareaService.DevolverComentariosTareas(this.tar_codigo)
      .subscribe({
        next: (response) => {
          this.listaComTareas = response as Tarea_Comentario[];
          this.dataSourceComTareas = new MatTableDataSource<Tarea_Comentario>(this.listaComTareas);
          this.dataSourceComTareas.sort = this.sort1;         
          this.isLoadingResults = false;
        },
        error: (error) => {
          console.error(error)
        }
      });
  }

  Volver(): void {
    if (this.valor == 1) {
      this.router.navigateByUrl('/tarea');
    }
    if (this.valor == 2) {
      history.back();
    }
    
  }




}
