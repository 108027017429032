import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Empresa } from '../../Entidades/Empresa';
import { MatDialog } from '@angular/material/dialog';
import { Requerimiento } from '../../Entidades/Requerimiento';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { HorasService } from '../../_services/horas.service';
import { MatRadioModule } from '@angular/material/radio';
import { Horas } from '../../Entidades/Horas';
import { DlgTareaDelMesPorEmpresaComponent } from './dlgtareadelmesporempresa.component';
import { DialogAlertaSimpleComponent } from '../alerta/dialog-alerta-simple.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-horas',
  templateUrl: './horas.component.html',
  styleUrls: ['./horas.component.css'],
})

export class HorasComponent implements OnInit {

  isLoadingResults = false;
  listaEmpresa: Empresa[] = [];
  selectedEmpresa: number = 0;
  filtroEmpresas: any[] = [];
  selectedOption: string = "1";

  searchTerm: string = '';
  listaEmpresas: any[] = [this.listaEmpresa];
  listaHoras: Horas[] = [];
  listaHorasSeleccionada: Horas[] = [];

  columnas: string[] = ['fecha', 'SinAsignar', 'ImplemPtes', 'Asignadas', 'Finalizadas', 'HsProc', 'HsSoporte', 'ProySinAsignar', 'ProyAsignadas', 'ProyERE', 'ProyFinalizadas', 'ProyProceso']

  totalSinAsignar: number = 0;
  totalImplemPtes: number = 0;
  totalAsignadas?: number = 0;
  totalFinalizadas?: number = 0;
  totalHsProc?: number = 0;
  totalHsSoporte?: number = 0;
  totalProySinAsignar?: number = 0;
  totalProyAsignadas?: number = 0;
  totalProyERE?: number = 0;
  totalProyFinalizadas?: number = 0;
  totalProyProceso?: number = 0;

  totalSeleccionadoSinAsignar: number = 0;
  totalSeleccionadoImplemPtes: number = 0;
  totalSeleccionadoAsignadas: number = 0;
  totalSeleccionadoFinalizadas: number = 0;
  totalSeleccionadoHsProc: number = 0;
  totalSeleccionadoHsSoporte: number = 0;
  totalSeleccionadoProySinAsignar: number = 0;
  totalSeleccionadoProyAsignadas: number = 0;
  totalSeleccionadoProyERE: number = 0;
  totalSeleccionadoProyFinalizadas: number = 0;
  totalSeleccionadoProyProceso: number = 0;

  promedioSinAsignar: number = 0;
  promedioImplemPtes: number = 0;
  promedioAsignadas: number = 0;
  promedioFinalizadas: number = 0;
  promedioHsProc: number = 0;
  promedioHsSoporte: number = 0;
  promedioProySinAsignar: number = 0;
  promedioProyAsignadas: number = 0;
  promedioProyERE: number = 0;
  promedioProyFinalizadas: number = 0;
  promedioProyProceso: number = 0;

  dataSourceHoras!: MatTableDataSource<Horas>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private horasService: HorasService, private _snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
   
  }

  vaciarTotalesSeleccionados() {

    this.totalSeleccionadoSinAsignar = 0;
    this.totalSeleccionadoImplemPtes = 0;
    this.totalSeleccionadoAsignadas = 0;
    this.totalSeleccionadoFinalizadas = 0;
    this.totalSeleccionadoHsProc = 0;
    this.totalSeleccionadoHsSoporte = 0;
    this.totalSeleccionadoProySinAsignar = 0;
    this.totalSeleccionadoProyAsignadas = 0;
    this.totalSeleccionadoProyERE = 0;
    this.totalSeleccionadoProyFinalizadas = 0;
    this.totalSeleccionadoProyProceso = 0;

    this.promedioSinAsignar = 0;
    this.promedioImplemPtes = 0;
    this.promedioAsignadas = 0;
    this.promedioFinalizadas = 0;
    this.promedioHsProc = 0;
    this.promedioHsSoporte = 0;
    this.promedioProySinAsignar = 0;
    this.promedioProyAsignadas = 0;
    this.promedioProyERE = 0;
    this.promedioProyFinalizadas = 0;
    this.promedioProyProceso = 0;

  }

  ngOnInit(): void {

    this.DevolverListadoEmpresas();
    this.vaciarTotalesSeleccionados();
 
  }

  ngAfterViewInit() {

    var filtroJSON = localStorage.getItem('filtro');

    if (filtroJSON && localStorage.getItem('filtro') != "{}") {
      var filtro = JSON.parse(filtroJSON);
      this.selectedEmpresa = filtro.selectedEmpresa;
      this.selectedOption = filtro.selectedOption;
      this.DevolverListadoHoras();
    }

  }

  DevolverListadoEmpresas() {
    this.horasService.DevolverListadoEmpresas().subscribe(
      response => {
        this.listaEmpresa = response;
        if (this.listaEmpresa != null) {

          var nuevoItem: Empresa = new Empresa();
          nuevoItem.empr_codigo = 0;
          nuevoItem.empr_denom = "Todos";
          this.listaEmpresa = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  get filteredEmpresas() {
    if (!this.searchTerm.trim()) {
      return this.listaEmpresa;
    }
    return this.listaEmpresa.filter(em =>
      em.empr_denom.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  onSelectionChange() {
    this.searchTerm = '';
  }


  DevolverListadoHoras() {

    if (this.selectedOption == "0") {
      this.openSnackBar("Debe seleccionar una opción: Individual o Grupo Empresa", 'Ok');
      return;
    }

    if (this.selectedEmpresa == 0) {
      this.openSnackBar("Debe seleccionar una Empresa", 'Ok');
      return;
    }

    this.isLoadingResults = true;
    this.horasService.DevolverListadoHoras(this.selectedEmpresa, this.selectedOption)
      .subscribe({
        next: (response) => {
          this.listaHoras = response.listaHoras as Horas[];
          this.dataSourceHoras = new MatTableDataSource<Horas>(this.listaHoras);
          this.dataSourceHoras.sort = this.sort;
          this.dataSourceHoras.paginator = this.paginator;
          const filtro = { selectedEmpresa: this.selectedEmpresa, selectedOption: this.selectedOption }
          localStorage.setItem('filtro', JSON.stringify(filtro));

          this.isLoadingResults = false;

          this.totalSinAsignar = response.totalSinAsignar;
          this.totalImplemPtes = response.totalImplemPtes;
          this.totalAsignadas = response.totalAsignadas;
          this.totalFinalizadas = response.totalFinalizadas;
          this.totalHsProc = response.totalHsProc;
          this.totalHsSoporte = response.totalHsSoporte;
          this.totalProySinAsignar = response.totalProySinAsignar;
          this.totalProyAsignadas = response.totalProyAsignadas;
          this.totalProyERE = response.totalProyERE;
          this.totalProyFinalizadas = response.totalProyFinalizadas;
          this.totalProyProceso = response.totalProyProceso;
          this.vaciarTotalesSeleccionados();

        },
        error: (error) => {
          console.error(error);
          this.isLoadingResults = false;

        }
      });
  }


  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }

  MostrarHorasxEmpresa(row: any) {
    this.router.navigate(['/MostrarHorasxEmpresa', row.fecha, this.selectedEmpresa]);
  }

  seleccionarFila(row: any) {
    const index = this.listaHorasSeleccionada.indexOf(row);
    if (index === -1) {
      this.listaHorasSeleccionada.push(row);
    } else {
      this.listaHorasSeleccionada.splice(index, 1);
    }
    this.CalcularTotalxFila();
  }

  filaSeleccionada(fila: any): boolean {
    return this.listaHorasSeleccionada.indexOf(fila) !== -1;
  }

  CalcularTotalxFila() {

    this.vaciarTotalesSeleccionados();

    if (this.listaHorasSeleccionada != undefined && this.listaHorasSeleccionada != null && this.listaHorasSeleccionada.length > 0) {
      for (var i = 0; i < this.listaHorasSeleccionada.length; i++) {

         // Calcular total segun seleccion
        this.totalSeleccionadoSinAsignar += Number(this.listaHorasSeleccionada[i].sinAsignar);
        this.totalSeleccionadoImplemPtes += Number(this.listaHorasSeleccionada[i].implemPtes);
        this.totalSeleccionadoAsignadas += Number(this.listaHorasSeleccionada[i].asignadas);
        this.totalSeleccionadoFinalizadas += Number(this.listaHorasSeleccionada[i].finalizadas);
        this.totalSeleccionadoHsProc += Number(this.listaHorasSeleccionada[i].hsProc);
        this.totalSeleccionadoHsSoporte += Number(this.listaHorasSeleccionada[i].hsSoporte);
        this.totalSeleccionadoProySinAsignar += Number(this.listaHorasSeleccionada[i].proySinAsignar);
        this.totalSeleccionadoProyAsignadas += Number(this.listaHorasSeleccionada[i].proyAsignadas);
        this.totalSeleccionadoProyERE += Number(this.listaHorasSeleccionada[i].proyERE);
        this.totalSeleccionadoProyFinalizadas += Number(this.listaHorasSeleccionada[i].proyFinalizadas);
        this.totalSeleccionadoProyProceso += Number(this.listaHorasSeleccionada[i].proyProceso);

        // Calcular promedio segun seleccion
        this.promedioSinAsignar = parseFloat((this.totalSeleccionadoSinAsignar / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioImplemPtes = parseFloat((this.totalSeleccionadoImplemPtes / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioAsignadas = parseFloat((this.totalSeleccionadoAsignadas / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioFinalizadas = parseFloat((this.totalSeleccionadoFinalizadas / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioHsProc = parseFloat((this.totalSeleccionadoHsProc / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioHsSoporte = parseFloat((this.totalSeleccionadoHsSoporte / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioProySinAsignar = parseFloat((this.totalSeleccionadoProySinAsignar / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioProyAsignadas = parseFloat((this.totalSeleccionadoProyAsignadas / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioProyERE = parseFloat((this.totalSeleccionadoProyERE / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioProyFinalizadas = parseFloat((this.totalSeleccionadoProyFinalizadas / this.listaHorasSeleccionada.length).toFixed(1));
        this.promedioProyProceso = parseFloat((this.totalSeleccionadoProyProceso / this.listaHorasSeleccionada.length).toFixed(1));

      }
    }
  }


}





