import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Empresa } from '../../Entidades/Empresa';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Router } from '@angular/router'; // Importa el Router



@Component({
  selector: 'app-tablas2',
  templateUrl: './tablas2.component.html',
  styleUrls: ['./tablas2.component.css'],
})
export class Tablas2Component implements OnInit {
  formConsulta: FormGroup;
  router: Router;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private formBuilder: FormBuilder,
    private _router: Router
  ) {
    this.router = _router;
  }

  @ViewChild('termSort') termSort: MatSort;
  @ViewChild('pendSort') pendSort: MatSort;

  empresasListado: Empresa[];
  ngOnInit(): void {
    let usu_cod = localStorage.getItem("idUsuario");
    this.http.get<Empresa[]>(this.baseUrl + 'api/empresas/' + usu_cod).subscribe(result => {
      this.empresasListado = result;
      if (this.empresasListado.length == 1) {
        this.empresaSeleccionada = this.empresasListado[0];
        this.formConsulta.controls.empresa.setValue(this.empresaSeleccionada);
        this.consultar();
      }
    }, error => console.error(error));
    this.formConsulta = this.formBuilder.group({
      empresa: [null, [Validators.required]]
    })
  }


  columnasDinamicas: string[] = [];
  colDinamicas: Columna[] = [];
  tablaPendientes: MatTableDataSource<any>;
  tablaTerminadas: MatTableDataSource<any>;
  empresaSeleccionada: Empresa;
  filasListas: boolean;
  columnasListas: boolean;
  consultar() {
    this.filasListas = false;
    this.columnasListas = false;
    this.colDinamicas = [];
    this.columnasDinamicas = [];
    this.tablaPendientes = null;
    this.dataPend = null;
    this.dataTerm = null;
    this.tablaTerminadas = null;
    this.empresaSeleccionada = this.formConsulta.controls.empresa.value;
    this.http.get<Columna[]>(this.baseUrl + "api/empresas/columnas/" + this.empresaSeleccionada.empr_codigo).subscribe(
      result => {
        console.log(result)
        this.columnasDinamicas.push("ORDEN");
        this.colDinamicas.push({ col_id: "ORDEN", col_comment: "Orden", col_lenght: 80 })
        result.forEach(columna => {
          this.columnasDinamicas.push(columna.col_id);
          this.colDinamicas.push(columna);
        })
        this.columnasListas = true;
      }, error => console.error(error)
    );
    this.http.get<any>(this.baseUrl + "api/empresas/tickets/" + this.empresaSeleccionada.empr_codigo).subscribe(
      result => {
        console.log(result)
        this.dataPend = new MatTableDataSource(result[0]);
        this.dataTerm = new MatTableDataSource(result[1]);
        setTimeout(() => {
          this.fixDates(this.dataPend.filteredData);
          this.fixDates(this.dataTerm.filteredData);
        }, 100)
        this.tablaPendientes = new MatTableDataSource(result[0]);
        this.tablaTerminadas = new MatTableDataSource(result[1]);
        this.dataPend.sort = this.pendSort;
        this.dataTerm.sort = this.termSort;
        this.filasListas = true;
      }, error => console.error(error)
    );
  }

  fixDates(table: any[]) {

    table.forEach(element => {
      if (element.TAR_FECHAPRO != null) {
        let day: string = element.TAR_FECHAPRO.toString().split("T")[0].split("-")[2];
        let month: string = element.TAR_FECHAPRO.toString().split("T")[0].split("-")[1];
        let year: string = element.TAR_FECHAPRO.toString().split("T")[0].split("-")[0];
        element.TAR_FECHAPRO = day + "/" + month + "/" + year;
      }

      if (element.TAR_FECHAFIN != null) {
        let day2: string = element.TAR_FECHAFIN.toString().split("T")[0].split("-")[2];
        let month2: string = element.TAR_FECHAFIN.toString().split("T")[0].split("-")[1];
        let year2: string = element.TAR_FECHAFIN.toString().split("T")[0].split("-")[0];
        element.TAR_FECHAFIN = day2 + "/" + month2 + "/" + year2;
      }

      if (element.TAR_FECHASOL != null) {
        let day3: string = element.TAR_FECHASOL.toString().split("T")[0].split("-")[2];
        let month3: string = element.TAR_FECHASOL.toString().split("T")[0].split("-")[1];
        let year3: string = element.TAR_FECHASOL.toString().split("T")[0].split("-")[0];
        element.TAR_FECHASOL = day3 + "/" + month3 + "/" + year3;
      }
    });

  }

  getColorEstado(row: any) {
    var state: string = row.ETAR_DENOM
    var enProceso = '#A0F2F0';
    var desarrollo = '#fff';
    var critica = '#F44336';
    var soporte = '#8BC34A';
    var reclamo = '#FF4081';
    if (state == "En Proceso") {
      return enProceso
    }
    // else if (state == 3.8) {
    //   return desarrollo
    // } else if (state == 4.2) {
    //   return soporte
    // }
  }

  justifyContent(data) {
    if (typeof data === 'string') {
      return "flex-start"
    }
    return "flex-end"
  }


  exportTable(table: String) {
    if (table == "Pendientes") {
      this.exportTableToExcel('Pendientes', this.dataPend, this.colDinamicas);
    }
    else if (table == "Terminados") {
      this.exportTableToExcel('Terminados', this.dataTerm, this.colDinamicas);
    }
  }

  exportTableToExcel(tableId: string, table: any, cols: Columna[], name?: string) {
    var newTable: any[] = [];

    if (table.filteredData != null) {
      table.filteredData.forEach(element => {
        var newObject: any = new Object();

        cols.forEach(col => {
          newObject[col.col_comment] = element[col.col_id];
        });

        newTable.push(newObject);
      });
    }
    else {
      table.forEach(element => {
        var newObject: any = new Object();

        cols.forEach(col => {
          newObject[col.col_comment] = element[col.col_id];
        });

        newTable.push(newObject);
      });
    }

    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newTable)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "Hoja 1")
    var file = XLSX.writeFile(wb, "Tabla_Requerimientos_" + tableId + ".xlsx")
  }

  dataPend;
  dataTerm;
  sortData(sort: Sort, table: string) {
    let data;
    if (table == "pendientes") {
      data = this.tablaPendientes.data.slice();
    } else if (table == "terminados") {
      data = this.tablaTerminadas.data.slice();
    }
    if (sort.direction == '') {
      if (table == 'pendientes') {
        this.dataPend = data;
        return;
      }
      if (table == 'terminados') {
        this.dataTerm = data;
        return;
      }
    }
    let dataTable: MatTableDataSource<any>;
    dataTable = data.sort((a, b) => {
      let isAsc = sort.direction == 'asc';
      switch (sort.active.toLocaleLowerCase()) {
        case 'tar_fechasol': {
          if (a.TAR_FECHASOL != '' && b.TAR_FECHASOL != '') {
            let fechaSolStr = a.TAR_FECHASOL.toString().split("/");
            let afechaSol = fechaSolStr[2] + '-' + fechaSolStr[1] + '-' + fechaSolStr[0];
            fechaSolStr = b.TAR_FECHASOL.toString().split("/");
            let bfechaSol = fechaSolStr[2] + '-' + fechaSolStr[1] + '-' + fechaSolStr[0];

            return compare(afechaSol, bfechaSol, isAsc);
          }
        }
        case 'tar_fechapro': {
          if (a.TAR_FECHAPRO != '' && b.TAR_FECHAPRO != '') {
            let fechaProStr = a.TAR_FECHAPRO.toString().split("/");
            let afechaPro = fechaProStr[2] + '-' + fechaProStr[1] + '-' + fechaProStr[0];
            fechaProStr = b.TAR_FECHAPRO.toString().split("/");
            let bfechaPro = fechaProStr[2] + '-' + fechaProStr[1] + '-' + fechaProStr[0];

            return compare(afechaPro, bfechaPro, isAsc);
          }
        }
        case 'tar_fechafin': {
          if (a.TAR_FECHAFIN != '' && b.TAR_FECHAFIN != '') {
            let fechaFinStr = a.TAR_FECHAFIN.toString().split("/");
            let afechaFin = fechaFinStr[2] + '-' + fechaFinStr[1] + '-' + fechaFinStr[0];
            fechaFinStr = b.TAR_FECHAFIN.toString().split("/");
            let bfechaFin = fechaFinStr[2] + '-' + fechaFinStr[1] + '-' + fechaFinStr[0];

            return compare(afechaFin, bfechaFin, isAsc);
          }
        }
        default: {
          return compare(a[sort.active], b[sort.active], isAsc);
        }
      }
    })
    if (table == 'pendientes') {
      this.dataPend = dataTable;
    } if (table == 'terminados') {
      this.dataTerm = dataTable;
    }
  }
}

function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

interface Columna {
  col_lenght: number;
  col_id;
  col_comment;
}

