import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginService } from "../../Servicios/login.servicio";



@Component({
  selector: 'app-recuperar-usuario',
  templateUrl: './recuperar-usuario.component.html',
  styleUrls: ['./recuperar-usuario.component.css'],
})
export class RecuperarUsuario {
  mail: string = '';


  constructor(private loginService: LoginService, private _snackBar: MatSnackBar) { }



  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }

  recuperarPass(): void {
    this.loginService.recuperarUsuario(this.mail).subscribe(
      resp => {
        console.log(resp)
        if (resp === null || resp.alertas === undefined || resp.alertas.length === 0) {
          this.openSnackBar("El usuario no existe", "OK")
        } else { 
          this.openSnackBar(resp.alertas[0], 'OK');
        }
      }
     

    )
  }

}
