export class MenuItems {
  men_codigo: number;
  med_item: number;
  med_denom: string;
  proc_codigo: number;
  proc_formulario: string;
  tooltip: string;
  imagen: string;
  menu_padre: number;
  menuItemsHijos: MenuItems[];

  constructor() {
    this.men_codigo = 0;
    this.med_item = 0;
    this.med_denom = '';
    this.proc_codigo = 0;
    this.proc_formulario = '';
    this.tooltip = '';
    this.imagen = '';
    this.menu_padre = 0;
    this.menuItemsHijos = [];
  }
}
