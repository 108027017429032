import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isFuture from 'date-fns/isFuture';
import { addMinutes } from 'date-fns';
import { MenuesService } from './menues.service';
import { MenuItems } from '../Entidades/MenuItems';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    //private snackBar: MatSnackBar,
    @Inject('BASE_URL') private baseUrl: string,private menuService: MenuesService
  ) {
  }

  //login(body: object, ingresarBeta: boolean ) {
  //  return this.http.post(this.baseUrl + "api/login", body).subscribe(
  //    async res => {
  //      this.setSession(res);
  //      if (!(localStorage.getItem("token") == null)) {
  //        if (ingresarBeta) {
  //          this.router.navigate(['/tablas']);
  //        }
  //        else {
  //           //this.router.navigate(['/tablas2']);      
  //          var id_usuario = Number( localStorage.getItem('idUsuario'));
  //          await this.cargarMenu(id_usuario);
            
  //          this.router.navigate(['/home']);
  //        }
          
  //      }
  //    },
  //    err => {
  //      window.alert("Usuario o contraseña incorrecta");
  //      //this.router.navigate(['/tablas']);
  //    }
  //  );
  //}

  login(body: object, ingresarBeta: boolean): Observable<any> {
    return this.http.post(this.baseUrl + "api/login", body)
      .pipe(catchError(this.errorHandler));
  }

  cargarMenu(opew_codigo: number) {
    this.menuService.devolverMenuxUsuario(2, opew_codigo).subscribe({
      next: (response) => {
        var listamenu: MenuItems = response;
        if (listamenu) {
          localStorage.setItem('menu', JSON.stringify(listamenu))
        }       
      }
    });
}

  static getExpiracion() {
    let expiraEn = localStorage.getItem("expira");
    if (expiraEn) {
      return new Date(expiraEn);
    }
    return null;
  }

  public setSession(authResult: any) {
    let expiraEn = addMinutes(new Date(), authResult.expiracion);
    localStorage.setItem("token", authResult.token);
    localStorage.setItem("expira", expiraEn.toJSON());
    localStorage.setItem("idUsuario", authResult.codigo);
    localStorage.setItem("usu_denom", authResult.usuario);
  }

  public static logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("expira");
    localStorage.removeItem("idUsuario");
    localStorage.removeItem("usu_denom");
  }

  public static esSesionValida(): boolean{
    return isFuture(this.getExpiracion());
  }

  errorHandler(error): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Error del lado del cliente
      errorMessage = error.error.message;
    } else {
      // Error del lado del servidor
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(error);
  }
}
