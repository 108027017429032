import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from "xlsx";
import { Empresa } from '../../Entidades/Empresa';
import { TareasService } from '../../_services/tareas-service';
import { MatDialog } from '@angular/material/dialog';
import { EstadoTarea } from '../../Entidades/EstadoTarea';
import { Requerimiento } from '../../Entidades/Requerimiento';
import { MatPaginator } from '@angular/material/paginator';
import { DlgMostrarTareaComponent } from './dlgmostrartarea.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GrupoEstadoTarea } from '../../Entidades/GrupoEstadoTareas';



@Component({
  selector: 'app-tarea',
  templateUrl: './tarea.component.html',
  styleUrls: ['./tarea.component.css'],
})

export class TareaComponent implements OnInit {

  listaTareas: Requerimiento[] = [];
  listaTareasSeleccionadas: Requerimiento[] = [];
  columnasTareas: string[] = ['empr_razonsocial', 'tar_codigo', 'tar_denom', 'tar_fechasol', 'tar_fechafin', 'etar_denom','hsimputadas']
  selectedEmpresa: number = 0;
  listaEmpresa: Empresa[] = [];
  filtroEmpresas: any[] = [];
  selectedEstado: number = 0;
  listaEstados: GrupoEstadoTarea[] = [];
  requerimiento: Requerimiento = new Requerimiento();
  dataSourceTareas!: MatTableDataSource<Requerimiento>;
  isLoadingResults = false;
  textoBuscar: string = "";

  searchTerm: string = '';
  buscarTerm: string = '';
  listaEmpresas: any[] = [this.listaEmpresa];


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private tareaService: TareasService, private _snackBar: MatSnackBar, private dialog: MatDialog,
    private router: Router, private route: ActivatedRoute)
  {   
  }

  ngOnInit(): void {
    this.DevolverListadoEmpresas();
    this.DevolverEstadosTareas();
  }

  ngAfterViewInit()
  {
    var filtroTareasJSON = localStorage.getItem('filtroTareas');

    if (filtroTareasJSON && localStorage.getItem('filtroTareas') != "{}") {
      var filtro = JSON.parse(filtroTareasJSON);
      this.selectedEmpresa = filtro.selectedEmpresa;
      this.selectedEstado = filtro.selectedEstado;
      this.textoBuscar = filtro.textoBuscar;       
      this.DevolverListadoTareas();
    }  
   
  }


  get filteredEmpresas() {
    if (!this.searchTerm.trim()) {
      return this.listaEmpresa; 
    }
    return this.listaEmpresa.filter(em =>
      em.empr_denom.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get filteredEstados() {
    if (!this.buscarTerm.trim()) {
      return this.listaEstados;
    }
    return this.listaEstados.filter(es =>
      es.get_denom.toLowerCase().includes(this.buscarTerm.toLowerCase())
    );
  }

  onSelectionChange() {
    this.searchTerm = '';
    this.buscarTerm = '';
  }


  DevolverListadoEmpresas() {
    this.tareaService.DevolverListadoEmpresas().subscribe(
      response => {
        this.listaEmpresa = response;
        if (this.listaEmpresa != null) {

          var nuevoItem: Empresa = new Empresa();
          nuevoItem.empr_codigo = 0;
          nuevoItem.empr_denom = "Todos";
          this.listaEmpresa = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }


  DevolverEstadosTareas() {
    this.tareaService.DevolverEstadosTareas().subscribe(
      response => {
        this.listaEstados = response;
        if (this.listaEstados != null) {

          var nuevoItem: GrupoEstadoTarea = new GrupoEstadoTarea();
          nuevoItem.get_codigo = 0;
          nuevoItem.get_denom = "Todos";
          this.listaEstados = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }


  DevolverListadoTareas() {
    this.isLoadingResults = true;
    this.tareaService.DevolverListadoTareas(this.selectedEmpresa, this.selectedEstado, this.textoBuscar)
      .subscribe({
        next: (response) => {
          this.listaTareas = response as Requerimiento[];
          if (this.listaTareas.length === 0) {
            this.openSnackBar('No se encontraron datos.', 'OK');
            this.dataSourceTareas = new MatTableDataSource<Requerimiento>([]);
          } else {
            this.dataSourceTareas = new MatTableDataSource<Requerimiento>(this.listaTareas);
            this.dataSourceTareas.sort = this.sort;
            this.dataSourceTareas.paginator = this.paginator;
            const filtro = { selectedEmpresa: this.selectedEmpresa, selectedEstado: this.selectedEstado, textoBuscar: this.textoBuscar }
            localStorage.setItem('filtroTareas', JSON.stringify(filtro));
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          console.error(error);
          this.isLoadingResults = false;
        }
      });
  }


  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 5000,
      panelClass: ['alerta-snackbar']
    });
  }

  mostrarTarea(row: any, valor: number) {
    this.router.navigate(['/mostrarTarea', row.tar_codigo, valor]);
  }

  seleccionarFila(row: any) {
    const index = this.listaTareasSeleccionadas.indexOf(row);
    if (index === -1) {
      this.listaTareasSeleccionadas.push(row);
    } else {
      this.listaTareasSeleccionadas.splice(index, 1);
    }
  }

  filaSeleccionada(fila: any): boolean {
    return this.listaTareasSeleccionadas.indexOf(fila) !== -1;
  }




}
