import { Component, OnInit, ViewChild } from "@angular/core";
import { ReclamosService } from "../../_services/reclamos.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Empresa } from '../../Entidades/Empresa';
import { Motivo_Reclamo } from '../../Entidades/Motivo_Reclamo';
import { Reclamos } from "../../Entidades/Reclamos";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Usuario } from "../../Entidades/Usuario";
import { MatDatepickerModule } from '@angular/material/datepicker';


@Component({
  selector: 'app-Consulta_Reclamos',
  templateUrl: './Consulta_Reclamos.component.html',
  styleUrls: ['./Consulta_Reclamos.component.css'],
})

export class Consulta_ReclamosComponent implements OnInit {

  usu_codigo: string = "";
  usu_denom: string = "";
  listaEmpresa: Empresa[] = [];
  listaMotivos: Motivo_Reclamo[] = [];
  selectedEmpresa: number = 0;
  selectedMotivo: number = 0;
  reclamo: Reclamos = new Reclamos();
  rec_codigo: number = 0;
  selectedValue: number = 1;
  isLoadingResults = false;

  txtfechaDesde!: string;
  txtfechaHasta!: string;

  searchTerm: string = '';
  buscarTerm: string = '';

  buscar: string = '';

  rec_validoStr: string = '';

  habilitado: boolean = false;

  columnas: string[] = ['rec_codigo', 'rec_fecha', 'empr_denom', 'mot_denom', 'rec_descrip', 'rec_respu', 'usu_denom', 'usu_interno_denom', 'rec_valido']

  listaReclamos: Reclamos[] = [];
  listaReclamosSeleccionados: Reclamos[] = [];
  dataSourceReclamos!: MatTableDataSource<Reclamos>;

  buscarTermino: string = '';
  listaUsuariosInternos: Usuario[] = [];
  selectedOperador: number = 0;

  listaUsuarios: Usuario[] = [];
  selectedUsuario: number = 0;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private reclamoService: ReclamosService, private _snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private route: ActivatedRoute) {
   
  }


  ngOnInit(): void {

    this.usu_codigo = localStorage.getItem("idUsuario");
    this.usu_denom = localStorage.getItem("usu_denom");
    this.DevolverListadoMotivosReclamos();
    this.DevolverListadoEmpresas();
    this.DevolverUsuariosInternos();
    this.DevolverUsuarios();
  }


  //private formatDate(date: Date): string {
  //  const day = date.getDate().toString().padStart(2, '0');
  //  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //  const year = date.getFullYear();
  //  return `${day}/${month}/${year}`;
  //}

  private formatInputDate(dateStr: string): string {
    if (dateStr.length === 8 && !isNaN(Number(dateStr))) {
      const day = dateStr.substring(0, 2);
      const month = dateStr.substring(2, 4);
      const year = dateStr.substring(4, 8);
      return `${day}/${month}/${year}`;
    }
    return dateStr; // Retorna la cadena original si no tiene el formato esperado
  }

    private formatDate(date: Date): string {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate().toString();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }


  get filteredEmpresas() {
    if (!this.searchTerm.trim()) {
      return this.listaEmpresa;
    }
    return this.listaEmpresa.filter(em =>
      em.empr_denom.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  get filteredMotivos() {
    if (!this.buscarTerm.trim()) {
      return this.listaMotivos;
    }
    return this.listaMotivos.filter(mot =>
      mot.mot_denom.toLowerCase().includes(this.buscarTerm.toLowerCase())
    );
  }

  get filteredOperadores() {
    if (!this.buscarTermino.trim()) {
      return this.listaUsuariosInternos;
    }
    return this.listaUsuariosInternos.filter(usu =>
      usu.usu_denom.toLowerCase().includes(this.buscarTermino.toLowerCase())
    );
  }

  get filteredUsuarios() {
    if (!this.buscar.trim()) {
      return this.listaUsuarios;
    }
    return this.listaUsuarios.filter(usua =>
      usua.usu_denom.toLowerCase().includes(this.buscar.toLowerCase())
    );
  }

  onSelectionChange() {
    this.searchTerm = '';
  }

  onSelectionChange2() {
    this.buscarTerm = '';
  }

  onSelectionChange3() {
    this.buscarTermino = '';
  }

  onSelectionChange4() {
    this.buscar = '';
  }


  DevolverListadoEmpresas() {
    this.reclamoService.DevolverListadoEmpresas(this.usu_codigo).subscribe(
      response => {
        this.listaEmpresa = response;

        if (this.listaEmpresa != null) {
          var nuevoItem: Empresa = new Empresa();
          nuevoItem.empr_codigo = 0;
          nuevoItem.empr_denom = "Todos";
          this.listaEmpresa = [nuevoItem, ...response];
        }

        if (this.listaEmpresa == null) {
          this.openSnackBar("El usuario no tiene una empresa asignada", 'Ok');
        }
      
      },
      error => {
        console.error(error)
      }
    );
  }

  DevolverUsuariosInternos() {
    this.reclamoService.DevolverUsuariosInternos().subscribe(
      response => {
        this.listaUsuariosInternos = response;

        if (this.listaUsuariosInternos != null) {
          var nuevoItem: Usuario = new Usuario();
          nuevoItem.usu_codigo = 0;
          nuevoItem.usu_denom = "Todos";
          this.listaUsuariosInternos = [nuevoItem, ...response];
        }

      },
      error => {
        console.error(error)
      }
    );
  }

  DevolverUsuarios() {

    this.reclamoService.DevolverUsuarios().subscribe(
      response => {
        this.listaUsuarios = response;

        if (this.listaUsuarios != null) {
          var nuevoItem: Usuario = new Usuario();
          nuevoItem.usu_codigo = 0;
          nuevoItem.usu_denom = "Todos";
          this.listaUsuarios = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  DevolverListadoMotivosReclamos() {
    this.reclamoService.DevolverListadoMotivosReclamos().subscribe(
      response => {
        this.listaMotivos = response;

        if (this.listaMotivos != null) {

          var nuevoItem: Motivo_Reclamo = new Motivo_Reclamo();
          nuevoItem.mot_codigo = 0;
          nuevoItem.mot_denom = "Todos";
          this.listaMotivos = [nuevoItem, ...response];
        }
      },
      error => {
        console.error(error)
      }
    );
  }

  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 3000,
      panelClass: ['alerta-snackbar']
    });
  }

  validarCampos(): boolean {

    if (!this.txtfechaDesde) {
      this.openSnackBar("Debe seleccionar Fecha Desde", 'Ok');
      return false;
    }

    if (!this.txtfechaHasta) {
      this.openSnackBar("Debe seleccionar Fecha Hasta", 'Ok');
      return false;
    }

    return true;
  }


  private inicializarForm(): void {
    this.rec_codigo = 0;
    this.selectedEmpresa = 0;
    this.selectedMotivo = 0;
    this.selectedOperador = 0;
    this.selectedUsuario = 0;
  }

  DevolverConsultaReclamos() {
    this.isLoadingResults = true;
    if (this.validarCampos()) {  
      this.reclamoService.DevolverConsultaReclamos(this.rec_codigo, new Date(this.txtfechaDesde), new Date(this.txtfechaHasta), this.selectedEmpresa, this.selectedMotivo, this.selectedValue, this.selectedUsuario, this.selectedOperador )
        .subscribe({
          next: (response) => {
            if (!response) {
              this.listaReclamos = [];
              this.openSnackBar('No se encontraron reclamos registrados.', 'OK');
            } else {
              this.listaReclamos = response as Reclamos[];
              if (this.listaReclamos.length === 0) {
                this.openSnackBar('No se encontraron reclamos registrados.', 'OK');
              }
            }
            this.dataSourceReclamos = new MatTableDataSource<Reclamos>(this.listaReclamos);
            this.dataSourceReclamos.sort = this.sort;
            this.dataSourceReclamos.paginator = this.paginator;
            this.isLoadingResults = false;
          },
          error: (error) => {
            console.error('Error al obtener la lista de reclamos:', error);
            this.openSnackBar('Ocurrió un error al obtener los datos.', 'OK');
            this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
            this.isLoadingResults = false;
          }
        });
    } else {

      this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
      this.isLoadingResults = false;
    }
    // alert(" CODIGO = " + this.rec_codigo + ` Fecha Desde: ${this.txtfechaDesde}\nFecha Hasta: ${this.txtfechaHasta}` + " EMPRESA = " + this.selectedEmpresa + " MOTIVO = " + this.selectedMotivo + " VALIDEZ = " + this.selectedValue + " OPERADOR = " + this.selectedOperador + " USUARIO = " + this.selectedUsuario);
  }


  seleccionarFila(row: any) {
    const index = this.listaReclamosSeleccionados.indexOf(row);
    if (index === -1) {
      this.listaReclamosSeleccionados.push(row);
    } else {
      this.listaReclamosSeleccionados.splice(index, 1);
    }

  }

  filaSeleccionada(fila: any): boolean {
    return this.listaReclamosSeleccionados.indexOf(fila) !== -1;
  }


  Cancelar() {
    this.inicializarForm();
    this.dataSourceReclamos = new MatTableDataSource<Reclamos>([]);
  }


}
