import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { MenuesService } from '../../_services/menues.service';


@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})

export class TopNavComponent implements OnInit {
  @Input() InputCurrentRoute!: string;
  currentRoute: string = '';
  username: string = '';
  dat = new Date();
  showToolbar: boolean = true;
  router: Router;

  constructor(public service: MenuesService, private _router: Router) {
   
      this.username = 'Bienvenido ' + localStorage.getItem('usu_denom');
        
    this.router = _router;
  }

  ngOnInit(): void {
    this.updateCurrentRoute();
    this.setUsername();
  }

  updateCurrentRoute(): void {
    switch (this.InputCurrentRoute) {
      case '/homeAdmin':
        this.currentRoute = 'Menu Admin.';
        break;
      case '/consultaProduccion':
        this.currentRoute = 'Consulta de Producción';
        break;
      // Otros casos...
      default:
        this.currentRoute = '';
        break;
    }
  }

  setUsername(): void {
    const usernameFromStorage = localStorage.getItem('usu_denom');
    if (AuthService.esSesionValida() && usernameFromStorage) {
      this.username = 'Bienvenido ' + usernameFromStorage;
    } else {
      this.username = 'Sesión expirada';
    }
  }

  //cerrarSesion(): void {
  //  if (this.service.abierto) {
  //    this.service.cerrarMenu();
  //  }
  //  // Comentado temporalmente para evitar errores
  //  // this.router.navigate(['login']);
  //}

  esSesionValida(): boolean {
    if (AuthService.esSesionValida()) {
     // this.usu_denom = localStorage.getItem("usu_denom");      
      return true;
    }
    else {
      this.cerrarSesion();
      return false;
    }
  }

  cerrarSesion() {
    if (this.service.abierto) {
      this.service.cerrarMenu();
    }
    AuthService.logout();
  }


}
